// Static Data
import utilityConst from "../../../../const/utilityConst";
// Logo
import SugarbookLogo from "../../../../assets/logo/sugarbook/brand-logo.svg";
import SugarbookChristmasLogo from "../../../../assets/logo/sugarbook/christmas-brand-logo.png";
import SugarbookCNYLogo from "../../../../assets/logo/sugarbook/cny-brand-logo.png";
import SugarbookValentineLogo from "../../../../assets/logo/sugarbook/valentine-brand-logo.png";
import SugarbookRayaLogo from "../../../../assets/logo/sugarbook/raya-brand-logo.png";
import SugarbookTaglineLogoEn from "../../../../assets/logo/sugarbook/en/brand-logo-with-description.svg";
import SugarbookTaglineWhiteLogoEn from "../../../../assets/logo/sugarbook/en/white-brand-logo-with-description.png";
import SugarbookChristmasTaglineLogoEn from "../../../../assets/logo/sugarbook/en/christmas-brand-logo-with-description.png";
import SugarbookChristmasTaglineWhiteLogoEn from "../../../../assets/logo/sugarbook/en/christmas-brand-logo-with-description-white.png";
import SugarbookCNYTaglineLogoEn from "../../../../assets/logo/sugarbook/en/cny-brand-logo-with-description.png";
import SugarbookCNYTaglineWhiteLogoEn from "../../../../assets/logo/sugarbook/en/cny-brand-logo-with-description-white.png";
import SugarbookValentineTaglineLogoEn from "../../../../assets/logo/sugarbook/en/valentine-brand-logo-with-description.png";
import SugarbookValentineTaglineWhiteLogoEn from "../../../../assets/logo/sugarbook/en/valentine-brand-logo-with-description-white.png";
import SugarbookRayaTaglineLogoEn from "../../../../assets/logo/sugarbook/en/raya-brand-logo-with-description.png";
import SugarbookRayaTaglineWhiteLogoEn from "../../../../assets/logo/sugarbook/en/raya-brand-logo-with-description-white.png";
import SugarbookTaglineLogoZh from "../../../../assets/logo/sugarbook/zh-tw/brand-logo-with-description.svg";
import SugarbookTaglineWhiteLogoZh from "../../../../assets/logo/sugarbook/zh-tw/white-brand-logo-with-description.png";
import SugarbookChristmasTaglineLogoZh from "../../../../assets/logo/sugarbook/zh-tw/christmas-brand-logo-with-description.png";
import SugarbookChristmasTaglineWhiteLogoZh from "../../../../assets/logo/sugarbook/zh-tw/christmas-brand-logo-with-description-white.png";
import SugarbookCNYTaglineLogoZh from "../../../../assets/logo/sugarbook/zh-tw/cny-brand-logo-with-description.png";
import SugarbookCNYTaglineWhiteLogoZh from "../../../../assets/logo/sugarbook/zh-tw/cny-brand-logo-with-description-white.png";
import SugarbookValentineTaglineLogoZh from "../../../../assets/logo/sugarbook/zh-tw/valentine-brand-logo-with-description.png";
import SugarbookValentineTaglineWhiteLogoZh from "../../../../assets/logo/sugarbook/zh-tw/valentine-brand-logo-with-description-white.png";
import SugarbookRayaTaglineLogoZh from "../../../../assets/logo/sugarbook/zh-tw/raya-brand-logo-with-description.png";
import SugarbookRayaTaglineWhiteLogoZh from "../../../../assets/logo/sugarbook/zh-tw/raya-brand-logo-with-description-white.png";
import SugarbookTaglineLogoJa from "../../../../assets/logo/sugarbook/ja/brand-logo-with-description.svg";
import SugarbookTaglineWhiteLogoJa from "../../../../assets/logo/sugarbook/ja/white-brand-logo-with-description.png";
import SugarbookChristmasTaglineLogoJa from "../../../../assets/logo/sugarbook/ja/christmas-brand-logo-with-description.png";
import SugarbookChristmasTaglineWhiteLogoJa from "../../../../assets/logo/sugarbook/ja/christmas-brand-logo-with-description-white.png";
import SugarbookCNYTaglineLogoJa from "../../../../assets/logo/sugarbook/ja/cny-brand-logo-with-description.png";
import SugarbookCNYTaglineWhiteLogoJa from "../../../../assets/logo/sugarbook/ja/cny-brand-logo-with-description-white.png";
import SugarbookValentineTaglineLogoJa from "../../../../assets/logo/sugarbook/ja/valentine-brand-logo-with-description.png";
import SugarbookValentineTaglineWhiteLogoJa from "../../../../assets/logo/sugarbook/ja/valentine-brand-logo-with-description-white.png";
import SugarbookRayaTaglineLogoJa from "../../../../assets/logo/sugarbook/ja/raya-brand-logo-with-description.png";
import SugarbookRayaTaglineWhiteLogoJa from "../../../../assets/logo/sugarbook/ja/raya-brand-logo-with-description-white.png";
import SugarbookAppLogo from "../../../../assets/logo/sugarbook/pwa-logo.png";
import SugarbookWhiteLogo from "../../../../assets/logo/sugarbook/sugarbook-white-logo.png";
import FacebookLogo from "../../../../assets/logo/social-media/Facebook_Logo_Primary.png";
import Visa from "../../../../assets/logo/payment/visa.svg";
import MasterCard from "../../../../assets/logo/payment/mastercard.svg";
import Maestro from "../../../../assets/logo/payment/maestro.svg";
import VisaElectron from "../../../../assets/logo/payment/electron.svg";
import Bleue from "../../../../assets/logo/payment/bleue.svg";
import Diners from "../../../../assets/logo/payment/diners.svg";
import Discover from "../../../../assets/logo/payment/discover.svg";
import JCB from "../../../../assets/logo/payment/jcb.svg";
import FpxLogo from "../../../../assets/logo/payment/fpx-logo.png";
// Icon
import SugarbookIcon from "../../../../assets/logo/sugarbook/app-icon.png";
import HomeIcon from "../../../../assets/icon/clickable/navbar/home-icon.svg";
import HomeIconSelected from "../../../../assets/icon/clickable/navbar/home-icon-selected.svg";
import MessagingIcon from "../../../../assets/icon/clickable/navbar/messaging-icon.svg";
import MessagingIconSelected from "../../../../assets/icon/clickable/navbar/messaging-icon-selected.svg";
import VideoIcon from "../../../../assets/icon/clickable/navbar/video-icon.svg";
import VideoIconSelected from "../../../../assets/icon/clickable/navbar/video-icon-selected.svg";
import LiveIcon from "../../../../assets/icon/clickable/navbar/live-icon.svg";
import LiveIconSelected from "../../../../assets/icon/clickable/navbar/live-icon-selected.svg";
import FavoriteIcon from "../../../../assets/icon/clickable/navbar/favorite-icon.svg";
import FavoriteIconSelected from "../../../../assets/icon/clickable/navbar/favorite-icon-selected.svg";
import NotificationIcon from "../../../../assets/icon/clickable/navbar/notification-icon.png";
import EmptyNotificationIcon from "../../../../assets/icon/static/shared/empty-notification-icon.png";
import ProfileIcon from "../../../../assets/icon/clickable/navbar/profile-icon.svg";
import ProfileIconSelected from "../../../../assets/icon/clickable/navbar/profile-icon-selected.svg";
import SearchFilterIcon from "../../../../assets/icon/clickable/search/search-filter.png";
import SearchFilteredIcon from "../../../../assets/icon/clickable/search/search-filtered.png";
import SearchDesktopIcon from "../../../../assets/icon/clickable/navbar/desktop-search.png";
import MessageDesktopIcon from "../../../../assets/icon/clickable/navbar/desktop-inbox.png";
import FavoriteDesktopIcon from "../../../../assets/icon/clickable/navbar/desktop-interest.png";
import VideoCallDesktopIcon from "../../../../assets/icon/clickable/navbar/desktop-video-call.png";
import LiveDesktopIcon from "../../../../assets/icon/clickable/navbar/desktop-live.png";
import LeaderboardIcon from "../../../../assets/icon/static/leaderboard/leaderboard-icon.svg";
import LeaderboardNewIconEn from "../../../../assets/icon/static/leaderboard/en/leaderboard-icon-new.svg";
import LeaderboardNewIconJa from "../../../../assets/icon/static/leaderboard/ja/leaderboard-icon-new.svg";
import LeaderboardNewIconZhTw from "../../../../assets/icon/static/leaderboard/zh-tw/leaderboard-icon-new.svg";
import SBCoin from "../../../../assets/icon/static/shared/live-coins.svg";
import SBCoinTick from "../../../../assets/icon/static/shared/sb-coin-tick.png";
import SBDiamond from "../../../../assets/icon/static/shared/live-diamonds.png";
import CalendarIcon from "../../../../assets/icon/clickable/shared/ic-calendar.svg";
import SettingsIcon from "../../../../assets/icon/clickable/shared/settings-icon.svg";
import BackIcon from "../../../../assets/icon/clickable/shared/close-icon-left.svg";
import BackLightIcon from "../../../../assets/icon/clickable/shared/close-icon-left-light.svg";
import CloseLightIcon from "../../../../assets/icon/clickable/shared/close-icon-light.svg";
import InfoIcon from "../../../../assets/icon/clickable/shared/info-icon.svg";
import VideoCall from "../../../../assets/icon/clickable/inbox/videocall.svg";
import AlertIcon from "../../../../assets/icon/static/inbox/alert.svg";
import BlueTick from "../../../../assets/icon/static/inbox/blue-tick.svg";
import GreyTick from "../../../../assets/icon/static/inbox/grey-tick.svg";
import RedSettingIcon from "../../../../assets/icon/clickable/inbox/red-gear-icon.svg";
import RedInfoIcon from "../../../../assets/icon/clickable/inbox/red-info-icon.svg";
import HelpIcon from "../../../../assets/icon/clickable/shared/help.svg";
import PkLogo from "../../../../assets/icon/static/live/pk-logo.png";
import PkVsIcon from "../../../../assets/icon/static/live/pk-vs-icon.png";
import LeaderboardPkIcon from "../../../../assets/icon/static/leaderboard/leaderboard-pk-tab.png";
import PkMedal from "../../../../assets/icon/static/live/1-medal.png";
import DefaultPhoto from "../../../../assets/icon/static/profile/nophoto_neutral.svg";
import VolumeUnmute from "../../../../assets/icon/clickable/live/unmute.svg";
import VolumeMute from "../../../../assets/icon/clickable/live/mute.svg";
import LiveReport from "../../../../assets/icon/clickable/live/live-report.svg";
import LiveBack from "../../../../assets/icon/clickable/live/live-back.svg";
import LiveViewer from "../../../../assets/icon/clickable/live/live-viewer-icon.svg";
import LiveAddCoin from "../../../../assets/icon/clickable/live/live-add-coin.svg";
import PinChatIcon from "../../../../assets/icon/static/live/pin-chat.png";
import AddBackground from "../../../../assets/background/profile/add.svg";
import InReview from "../../../../assets/icon/static/profile/in-review.svg";
import UploadIcon from "../../../../assets/icon/clickable/profile/upload.svg";
import PhotoIcons from "../../../../assets/icon/static/profile/photo.png";
import AboutMeIcon from "../../../../assets/icon/static/profile/about-me.png";
import AchievementIcon from "../../../../assets/icon/static/profile/achievement.png";
import PrivatePhotoLockIcon from "../../../../assets/icon/static/profile/locked-private-photo.png";
import PremiumLockIcon from "../../../../assets/icon/static/shared/premium-lock.svg";
import UserProfileCompleteIcon from "../../../../assets/icon/static/shared/user-profile-complete.png";
import MailFillIcon from "../../../../assets/icon/static/shared/mail-fill.svg";
import VideoCallUnavailable from "../../../../assets/icon/static/shared/popup-action-in-private.svg";
import CloseIcon from "../../../../assets/icon/clickable/shared/close-icon.svg";
import DeleteIcon from "../../../../assets/icon/static/inbox/delete.svg";
import BlockedMembersIcon from "../../../../assets/icon/static/shared/blocked-members.svg";
import DeactivateIcon from "../../../../assets/icon/static/shared/deactivate.svg";
import LocationPermissionIcon from "../../../../assets/icon/static/misc/location-permission.png";
import LocationPermissionDeniedIcon from "../../../../assets/icon/static/misc/location-permission-denied.png";
import PremiumUnlockIcon from "../../../../assets/icon/static/shared/dialog-upgrade.svg";
import ShareIcon from "../../../../assets/icon/static/shared/share-icon.svg";
import PlayOutlineIcon from "../../../../assets/icon/static/video-call/play-outline.png";
import ActionAlertIcon from "../../../../assets/icon/static/shared/popup-action-alert.svg";
import ActionSuccessIcon from "../../../../assets/icon/static/shared/popup-action-success.svg";
import DropPhotoIcon from "../../../../assets/icon/static/video-call/drop-photo.png";
import LowPowerIcon from "../../../../assets/icon/static/shared/low-power.png";
import NotificationPermissionIconEn from "../../../../assets/icon/static/misc/en/notification-permission.png";
import NotificationPermissionIconZhTw from "../../../../assets/icon/static/misc/zh-tw/notification-permission.png";
import PaymentErrorIcon from "../../../../assets/icon/static/payment/payment-error.png";
import PaymentWarningIcon from "../../../../assets/icon/static/payment/payment-warning.png";
import FlipIcon from "../../../../assets/icon/static/shared/rotate.svg";
import CloseProfileIcon from "../../../../assets/icon/clickable/profile/close.svg";
import DeleteProfileIcon from "../../../../assets/icon/clickable/profile/delete.svg";
import RejectedProfileIcon from "../../../../assets/icon/static/shared/profile-rejected.png";
import ProfileMessageIcon from "../../../../assets/icon/clickable/video-call/profile-message-icon.svg";
import ProfileVideoCallIcon from "../../../../assets/icon/clickable/video-call/video-call-icon.svg";
import ViewProfileIcon from "../../../../assets/icon/clickable/video-call/profile-icon.svg";
import AvatarFallbackMan from "../../../../assets/icon/static/shared/man_square.png";
import AvatarFallbackWoman from "../../../../assets/icon/static/shared/woman_square.png";
import AvatarFallbackNeutral from "../../../../assets/icon/static/shared/nophoto_neutral.svg";
import PhotoPermissionEmptyIcon from "../../../../assets/icon/static/interest/photo-permission-empty.png";
import PrivateStandbyIcon from "../../../../assets/icon/clickable/shared/go-private-icon.svg";
import GoldLockIcon from "../../../../assets/icon/static/shared/lock-gold.svg";
import MessageRedIcon from "../../../../assets/icon/clickable/profile/message-icon.svg";
import MessageWhiteIcon from "../../../../assets/icon/clickable/profile/message-icon-desktop.png";
import FavoriteRedIcon from "../../../../assets/icon/clickable/profile/favorite-icon.svg";
import FavoriteWhiteIcon from "../../../../assets/icon/clickable/profile/favorite-icon-desktop.png";
import FavoriteWhiteAltIcon from "../../../../assets/icon/clickable/shared/favorite-icon-white.svg";
import FavoritedRedIcon from "../../../../assets/icon/clickable/profile/favorited-icon.svg";
import FavoritedUserCardIcon from "../../../../assets/icon/clickable/profile/favorited-icon-user-card.svg";
import VideoCallRedIcon from "../../../../assets/icon/clickable/profile/video-call-icon.svg";
import MessagingOutlineIcon from "../../../../assets/icon/static/shared/message.svg";
import ViewedMeOutlineIcon from "../../../../assets/icon/static/shared/viewed-me.svg";
import LockWhiteIcon from "../../../../assets/icon/static/shared/lock-white.svg";
import ChargedMessageEnableIcon from "../../../../assets/icon/clickable/video-call/charged-message-enable.svg";
import ChargedMessageDisableIcon from "../../../../assets/icon/clickable/video-call/charged-message-disable.svg";
import ResetPermissionIcon from "../../../../assets/icon/clickable/video-call/video-call-icon-7.svg";
import StandbyKickIcon from "../../../../assets/icon/clickable/video-call/standby-kick.svg";
import EndCallIcon from "../../../../assets/icon/clickable/video-call/video-call-icon-4.svg";
import VideoCallMoreIcon from "../../../../assets/icon/clickable/video-call/more-icon.svg";
import MailOutlineIcon from "../../../../assets/icon/static/shared/mail.svg";
import PriceTagIcon from "../../../../assets/icon/static/upgrade/price-tag.svg";
import CreditCardOutlineIcon from "../../../../assets/icon/static/upgrade/credit_card.svg";
import WorldOutlineIcon from "../../../../assets/icon/static/upgrade/world.svg";
import UpgradePremiumIcon from "../../../../assets/icon/static/shared/premium.png";
import UpgradeStarIcon from "../../../../assets/icon/static/payment/star.svg";
import PaymentErrorAltIcon from "../../../../assets/icon/static/upgrade/payment_error.svg";
import CallSentIcon from "../../../../assets/icon/static/shared/call_sent.svg";
import VideoCallAvailableIcon from "../../../../assets/icon/static/video-call/video-call-available.svg";
import VideoCallMissedIcon from "../../../../assets/icon/static/video-call/video-call-missed.svg";
import PaymentSecureLockIcon from "../../../../assets/icon/static/payment/lock.svg";
import PaymentSecureShieldIcon from "../../../../assets/icon/static/payment/shield.svg";
import PauseIcon from "../../../../assets/icon/clickable/live/live-paused.svg";
import PlayIcon from "../../../../assets/icon/static/video-call/play-outline.png";
import FeedLikeIcon from "../../../../assets/icon/clickable/feed/like-icon.png";
import FeedSendTipIcon from "../../../../assets/icon/clickable/feed/tip-icon.png";
import FeedMessageIcon from "../../../../assets/icon/clickable/feed/message-icon.png";
import GoLivePlayIcon from "../../../../assets/icon/clickable/shared/go-live-play-icon.png";
import PinLocationIcon from "../../../../assets/icon/clickable/profile/location-icon.svg";
import PictureFrameIcon from "../../../../assets/icon/clickable/profile/picture-frame-icon.svg";
import PriceTagProfileIcon from "../../../../assets/icon/clickable/profile/price-tag-icon.svg";
import UserIcon from "../../../../assets/icon/clickable/profile/user-icon.svg";
import DescriptionIcon from "../../../../assets/icon/clickable/profile/description-icon.svg";
import BankIcon from "../../../../assets/icon/clickable/profile/bank-icon.svg";
import ProfileSmileyIcon from "../../../../assets/icon/clickable/profile/profile-smiley-icon.svg";
import MaterialLockIcon from "../../../../assets/icon/clickable/profile/material-lock-icon.svg";
import CameraBlueOutlineIcon from "../../../../assets/icon/clickable/profile/camera-blue-outline-icon.png";
import ShieldCheckOutlineIcon from "../../../../assets/icon/static/profile/shield-check-outline-icon.png";
import SafetyOutlineIcon from "../../../../assets/icon/static/profile/safety-outline-icon.png";
import RedCautionIcon from "../../../../assets/icon/static/shared/verified-rejected-caution.png";
import SignupBackIcon from "../../../../assets/icon/clickable/shared/signup-back-icon.png";
import BlueBulbIcon from "../../../../assets/icon/static/shared/blue-bulb-icon.png";
import LeaderboardCasinoChips from "../../../../assets/icon/static/leaderboard/leaderboard-casino-chips.png";
import CasinoGameIcon from "../../../../assets/icon/clickable/live/sb-casino-game-icon.png";
import DiceIcon from "../../../../assets/icon/clickable/game/sb-dice-icon.png";
import ThreeAceIcon from "../../../../assets/icon/clickable/game/sb-3-ace.png";
import TorCnyIcon from "../../../../assets/icon/static/profile/tor-cny-icon.png";
import DualPkCountdown1Icon from "../../../../assets/icon/static/live/dual-pk-countdown-1.png";
import DualPkCountdown2Icon from "../../../../assets/icon/static/live/dual-pk-countdown-2.png";
import DualPkCountdown3Icon from "../../../../assets/icon/static/live/dual-pk-countdown-3.png";
import DualPkRound1Icon from "../../../../assets/icon/static/live/dual-pk-round-1.png";
import DualPkRound2Icon from "../../../../assets/icon/static/live/dual-pk-round-2.png";
import DualPkRound3Icon from "../../../../assets/icon/static/live/dual-pk-round-3.png";
import DualPkRound4Icon from "../../../../assets/icon/static/live/dual-pk-round-4.png";
import DualPkRound5Icon from "../../../../assets/icon/static/live/dual-pk-round-5.png";
import DualPkFinalRoundIcon from "../../../../assets/icon/static/live/dual-pk-final-round.png";
import DualPkDrawRoundIcon from "../../../../assets/icon/static/live/dual-pk-draw-round.png";
import DualPkWinnerIcon from "../../../../assets/icon/static/live/dual-pk-winner.png";
import DualPkDrawEmojiIcon from "../../../../assets/icon/static/live/dual-pk-draw-emoji.png";
import DualPkWinEmojiIcon from "../../../../assets/icon/static/live/dual-pk-win-emoji.png";
import DualPkLoseEmojiIcon from "../../../../assets/icon/static/live/dual-pk-lose-emoji.png";
import DualPkLoseIcon from "../../../../assets/icon/static/live/dual-pk-lose-icon.png";
import DualPkWinIcon from "../../../../assets/icon/static/live/dual-pk-win-icon.png";
import DualPkFirstPlaceIcon from "../../../../assets/icon/static/shared/dual-pk-first-place.png";
import DualPkSecondPlaceIcon from "../../../../assets/icon/static/shared/dual-pk-second-place.png";
import DualPkThirdPlaceIcon from "../../../../assets/icon/static/shared/dual-pk-third-place.png";
import MobileCallingIcon from "../../../../assets/icon/static/shared/mobile-calling-icon.png";
import SwitchIcon from "../../../../assets/icon/clickable/shared/switch-icon.png";
import LightBulbIcon from "../../../../assets/icon/static/registration/light-bulb-icon.png";
import RegistrationSearchIcon from "../../../../assets/icon/clickable/registration/registration-search-icon.png";
import RegistrationIncomeIcon from "../../../../assets/icon/clickable/registration/registration-income-icon.png";
import RegistrationNetworthIcon from "../../../../assets/icon/clickable/registration/registration-net-worth-icon.png";
import RegistrationLifestyleIcon from "../../../../assets/icon/clickable/registration/registration-lifestyle-icon.png";
import RegistrationDatingBudgetIcon from "../../../../assets/icon/clickable/registration/dating-budget-white-icon.png";
import RegistrationRelationshipStatusIcon from "../../../../assets/icon/clickable/registration/registration-relationship-icon.png";
import RegistrationOccupationIcon from "../../../../assets/icon/clickable/registration/registration-occupation-icon.png";
import RegistrationDatingStylesIcon from "../../../../assets/icon/clickable/registration/registration-dating-styles-icon.png";
import RegistrationFantasiesIcon from "../../../../assets/icon/clickable/registration/registration-fantasies-icon.png";
import RegistrationEthnicityIcon from "../../../../assets/icon/clickable/registration/registration-ethnicity-icon.png";
import RegistrationSmokeIcon from "../../../../assets/icon/clickable/registration/registration-smoke-icon.png";
import RegistrationDrinkIcon from "../../../../assets/icon/clickable/registration/registration-drink-icon.png";
import RegistrationAlcoholIcon from "../../../../assets/icon/clickable/registration/registration-alcohol-icon.png";
import RegistrationAboutIcon from "../../../../assets/icon/clickable/registration/registration-about-icon.png";
import RegistrationLookingForIcon from "../../../../assets/icon/clickable/registration/registration-looking-for-icon.png";
import RegistrationLivestreamingIcon from "../../../../assets/icon/clickable/registration/registration-livestreaming-icon.png";
import RegistrationPhotoIcon from "../../../../assets/icon/clickable/registration/registration-photo-icon.png";
import RegistrationUploadPhotoIcon from "../../../../assets/icon/clickable/registration/registration-upload-photo-icon.png";
import RestartJourneyIcon from "../../../../assets/icon/static/registration/restart-journey-icon.png";
import SeriousVibesIcon from "../../../../assets/icon/static/registration/serious-vibes-icon.png";
import IceBreakerIcon from "../../../../assets/icon/static/registration/ice-breaker-icon.png";
import StandOutIcon from "../../../../assets/icon/static/registration/stand-out-icon.png";
import RedWineIcon from "../../../../assets/icon/clickable/registration/alcohol/red-wine-icon.svg";
import WhiteWineIcon from "../../../../assets/icon/clickable/registration/alcohol/white-wine-icon.svg";
import ChampagneIcon from "../../../../assets/icon/clickable/registration/alcohol/champagne-icon.svg";
import CognacIcon from "../../../../assets/icon/clickable/registration/alcohol/cognac-icon.svg";
import TequilaIcon from "../../../../assets/icon/clickable/registration/alcohol/tequila-icon.svg";
import BeerIcon from "../../../../assets/icon/clickable/registration/alcohol/beer-icon.svg";
import CocktailIcon from "../../../../assets/icon/clickable/registration/alcohol/cocktail-icon.svg";
import VodkaIcon from "../../../../assets/icon/clickable/registration/alcohol/vodka-icon.svg";
import BrandyIcon from "../../../../assets/icon/clickable/registration/alcohol/brandy-icon.svg";
import HighballIcon from "../../../../assets/icon/clickable/registration/alcohol/highball-icon.svg";
import WhiskeyIcon from "../../../../assets/icon/clickable/registration/alcohol/whiskey-icon.svg";
import LiqueurIcon from "../../../../assets/icon/clickable/registration/alcohol/liqueur-icon.svg";
import AbsintheIcon from "../../../../assets/icon/clickable/registration/alcohol/absinthe-icon.svg";
import RumIcon from "../../../../assets/icon/clickable/registration/alcohol/rum-icon.svg";
import SangriaIcon from "../../../../assets/icon/clickable/registration/alcohol/sangria-icon.svg";
import GinIcon from "../../../../assets/icon/clickable/registration/alcohol/gin-icon.svg";
import SakeIcon from "../../../../assets/icon/clickable/registration/alcohol/sake-icon.svg";
import SojuIcon from "../../../../assets/icon/clickable/registration/alcohol/soju-icon.svg";
import MartiniIcon from "../../../../assets/icon/clickable/registration/alcohol/martini-icon.svg";
import ShotsIcon from "../../../../assets/icon/clickable/registration/alcohol/shots-icon.svg";
import HeartFilledIcon from "../../../../assets/icon/static/registration/heart-filled-icon.png";
import MessageReadIcon from "../../../../assets/icon/static/registration/message-read-icon.png";
import ProfileViewIcon from "../../../../assets/icon/static/registration/profile-view-icon.png";
import ProfileApprovalIcon from "../../../../assets/icon/static/registration/profile-approval-icon.png";
import UnlimitedMessagesIcon from "../../../../assets/icon/static/registration/unlimited-messages-icon.png";
import UserSecretIcon from "../../../../assets/icon/static/registration/user-secret-icon.png";
import LocationSharpIcon from "../../../../assets/icon/static/registration/location-sharp-icon.png";
import CalendarSlashIcon from "../../../../assets/icon/static/registration/calendar-slash-icon.png";
import PremiumStarIcon from "../../../../assets/icon/static/registration/premium-star-icon.png";
import WhiteGiftIcon from "../../../../assets/icon/static/registration/white-gift-icon.png";
import CheckSquareIcon from "../../../../assets/icon/static/registration/check-square-icon.png";
import WhiteLoaderIcon from "../../../../assets/icon/static/registration/white-loader-icon.png";
import WhiteLockIcon from "../../../../assets/icon/static/registration/white-lock-icon.png";
import WhiteSearchFilterIcon from "../../../../assets/icon/static/registration/white-search-filter-icon.png";
import PersonalizedAboutMeIcon from "../../../../assets/icon/static/registration/personalized-about-me-icon.svg";
import LifestyleBudgetIcon from "../../../../assets/icon/static/edit-profile/lifestyle-budget-icon.png";
import DatingBudgetIcon from "../../../../assets/icon/static/edit-profile/dating-budget-icon.png";
import NetworthIcon from "../../../../assets/icon/static/edit-profile/networth-icon.png";
import AnnualIncomeIcon from "../../../../assets/icon/static/edit-profile/annual-income-icon.png";
import HeightIcon from "../../../../assets/icon/static/edit-profile/height-icon.png";
import BodyTypeIcon from "../../../../assets/icon/static/edit-profile/body-type-icon.png";
import EthnicityIcon from "../../../../assets/icon/static/edit-profile/ethnicity-icon.png";
import EyeColorIcon from "../../../../assets/icon/static/edit-profile/eye-color-icon.png";
import HairColorIcon from "../../../../assets/icon/static/edit-profile/hair-color-icon.png";
import OccupationIcon from "../../../../assets/icon/static/edit-profile/occupation-icon.png";
import EducationIcon from "../../../../assets/icon/static/edit-profile/education-icon.png";
import RelationshipIcon from "../../../../assets/icon/static/edit-profile/relationship-icon.png";
import KidsIcon from "../../../../assets/icon/static/edit-profile/kids-icon.png";
import SmokingIcon from "../../../../assets/icon/static/edit-profile/smoking-icon.png";
import DrinkingIcon from "../../../../assets/icon/static/edit-profile/drinking-icon.png";
import PersonalityTypeIcon from "../../../../assets/icon/static/edit-profile/personality-type-icon.png";
import StarSignIcon from "../../../../assets/icon/static/edit-profile/star-sign-icon.png";
import PersonalityTraitsIcon from "../../../../assets/icon/static/edit-profile/personality-traits-icon.png";
import DominantSubmissiveIcon from "../../../../assets/icon/static/edit-profile/dominant-submissive-icon.png";
import FitnessIcon from "../../../../assets/icon/static/edit-profile/fitness-icon.png";
import HobbiesIcon from "../../../../assets/icon/static/edit-profile/hobbies-icon.png";
import EditAboutMeIcon from "../../../../assets/icon/static/edit-profile/edit-about-me-icon.svg";
import ProfileRoleIcon from "../../../../assets/icon/static/profile/profile-role-icon.png";
import ProfileLookingForIcon from "../../../../assets/icon/static/profile/profile-looking-for-icon.png";
import SensitiveIcon from "../../../../assets/icon/static/profile/sensitive-icon.png";
import MasqueradeMaskIcon from "../../../../assets/icon/static/shared/masquerade-mask-icon.svg";
import LgbtqIcon from "../../../../assets/icon/static/shared/lgbtq-icon.svg";
import TooFarCrossIcon from "../../../../assets/icon/static/verification/too-far-cross-icon.svg";
import GoodFitTickIcon from "../../../../assets/icon/static/verification/good-fit-tick-icon.svg";
import GreenFilledTickIcon from "../../../../assets/icon/static/shared/green-filled-tick-icon.svg";
import CryptocurrencyIcon from "../../../../assets/icon/static/payment/cryptocurrency-icon.svg";
import CreditCardIcon from "../../../../assets/icon/static/payment/credit-card-icon.svg";
// Badge
import FreePremiumBadgeZhTw from "../../../../assets/badge/profile/zh/badge-free-premium-zh.svg";
import PremiumBadgeEn from "../../../../assets/badge/profile/en/badge-premium.png";
import PremiumBadgeJp from "../../../../assets/badge/profile/jp/badge-premium-ja.svg";
import PremiumBadgeZhTw from "../../../../assets/badge/profile/zh/badge-premium-zh.svg";
import DiamondBadgeZhTw from "../../../../assets/badge/profile/zh/badge-diamond-zh.svg";
import AdminBadgeEn from "../../../../assets/badge/profile/en/badge-admin-en.png";
import AdminBadgeZhTw from "../../../../assets/badge/profile/zh/badge-admin-zh.svg";
import PremiumMiniBadge from "../../../../assets/badge/profile/premium-mini-badge.svg";
import VerifiedProfile from "../../../../assets/badge/profile/verified-profile-badge.png";
import UnverifiedProfile from "../../../../assets/badge/profile/unverified-profile-badge.png";
import VerifiedProfileOutline from "../../../../assets/badge/profile/verified-profile-badge-outline.png";
import VerifiedCaller from "../../../../assets/badge/profile/verified-caller.png";
import MoreGiftsEn from "../../../../assets/icon/clickable/live/en/live-more-gifts.svg";
import MoreGiftsJp from "../../../../assets/icon/clickable/live/jp/live-more-gifts-ja.svg";
import MoreGiftsZh from "../../../../assets/icon/clickable/live/zh/live-more-gifts-zh.svg";
import SendMessageIcon from "../../../../assets/icon/clickable/live/send-message-icon.svg";
import SendMessageDarkIcon from "../../../../assets/icon/clickable/live/send-message-icon-dark.svg";
import BadgeRank1 from "../../../../assets/badge/shared/badge-rank-1.svg";
import BadgeRank2 from "../../../../assets/badge/shared/badge-rank-2.svg";
import BadgeRank3 from "../../../../assets/badge/shared/badge-rank-3.svg";
import NewBadge from "../../../../assets/badge/shared/en/new-badge.svg";
import SuperOfferEn from "../../../../assets/badge/coin/en/event-super-offer-badge.svg";
import MegaOfferEn from "../../../../assets/badge/coin/en/event-mega-offer-badge.svg";
import SuperOfferJp from "../../../../assets/badge/coin/jp/event-super-offer-badge-ja.svg";
import MegaOfferJp from "../../../../assets/badge/coin/jp/event-mega-offer-badge-ja.svg";
import SuperOfferZh from "../../../../assets/badge/coin/zh/event-super-offer-badge-zh.svg";
import MegaOfferZh from "../../../../assets/badge/coin/zh/event-mega-offer-badge-zh.svg";
import PrivateRoomIconEn from "../../../../assets/badge/video-call/en/go-private-button.png";
import PrivateRoomIconZhTw from "../../../../assets/badge/video-call/zh-tw/go-private-button.png";
import Tier1Badge from "../../../../assets/icon/static/leaderboard/badge-tier-1.png";
import Tier2Badge from "../../../../assets/icon/static/leaderboard/badge-tier-2.png";
import Tier3Badge from "../../../../assets/icon/static/leaderboard/badge-tier-3.png";
import Level1 from "../../../../assets/icon/static/levelling/level_1.png";
import Level12 from "../../../../assets/icon/static/levelling/level_12.png";
import Level23 from "../../../../assets/icon/static/levelling/level_23.png";
import Level34 from "../../../../assets/icon/static/levelling/level_34.png";
import Level45 from "../../../../assets/icon/static/levelling/level_45.png";
import Level55 from "../../../../assets/icon/static/levelling/level_55.png";
import TrophyRank1 from "../../../../assets/icon/static/leaderboard/trophy-rank-1.svg";
import TrophyRank2 from "../../../../assets/icon/static/leaderboard/trophy-rank-2.svg";
import TrophyRank3 from "../../../../assets/icon/static/leaderboard/trophy-rank-3.svg";
import DiscreteBadge from "../../../../assets/badge/payment/discreet-badge.svg";
import SecurityBadge from "../../../../assets/badge/payment/security-badge.svg";
import SslBadge from "../../../../assets/badge/payment/ssl-badge.svg";
import PayWithCryptoBadge from "../../../../assets/badge/payment/pay-with-crypto-badge.svg";
// Image
import TipCoins from "../../../../assets/icon/static/video-call/tip_coins.png";
import TipCoinsAccepted from "../../../../assets/icon/static/video-call/tip_coins_accepted.png";
import ChargedMessageSbPopupEnable from "../../../../assets/background/private-call/charged-message-sb-popup-enable.png";
import ChargedMessageSbPopupDisable from "../../../../assets/background/private-call/charged-message-sb-popup-disabled.png";
import ChargedMessageSdPopup from "../../../../assets/background/private-call/charged-message-sd-popup.png";
import SbPrivateRoomWarningImageEn from "../../../../assets/background/private-call/en/go-private-image.png";
import SbPrivateRoomWarningImageZhTw from "../../../../assets/background/private-call/zh-tw/go-private-image.png";
import ThankYouVIPImage from "../../../../assets/background/shared/thankyou-vip-header.svg";
// Emoji
import BlowKissEmoji from "../../../../assets/icon/clickable/live/emoji/face_blowing_a_kiss.svg";
import HeartEyesEmoji from "../../../../assets/icon/clickable/live/emoji/smiling_face_with_heart_eyes.svg";
import ThumbsUpEmoji from "../../../../assets/icon/clickable/live/emoji/c_thumbs_up.svg";
import FireEmoji from "../../../../assets/icon/clickable/live/emoji/fire.svg";
// Gif
import CallRequestGif from "../../../../assets/icon/static/inbox/call_request-anim.gif";
import CallAcceptGif from "../../../../assets/icon/static/inbox/call_accept-anim.gif";
import CallRejectGif from "../../../../assets/icon/static/inbox/call_reject-anim.gif";
import CallWaitingGif from "../../../../assets/icon/static/inbox/call_wait-anim.gif";
import CheckRedGif from "../../../../assets/gif/shared/check-red.gif";
// Background
import ChristmasDesktopBackground from "../../../../assets/background/shared/christmas-desktop-background.png";
import ChristmasMobileBackground from "../../../../assets/background/shared/christmas-mobile-background.png";
import ChristmasMobileBackgroundV2 from "../../../../assets/background/shared/christmas-mobile-background-v2.png";
import CoinShopBackground from "../../../../assets/background/shop/20p-coin-shop-background.jpg";
import MessagingStartBackgroundEn from "../../../../assets/background/inbox/en/messaging-no-conversation-chat-bg-v1.png";
import MessagingStartBackgroundZhTw from "../../../../assets/background/inbox/zh-tw/messaging-no-conversation-chat-bg-v1.png";
import ChristmasMessagingStartBackgroundEn from "../../../../assets/background/inbox/en/christmas-messaging-no-conversation-chat-bg-v1.png";
import ChristmasMessagingStartBackgroundJa from "../../../../assets/background/inbox/ja/christmas-messaging-no-conversation-chat-bg-v1.png";
import ChristmasMessagingStartBackgroundZhTw from "../../../../assets/background/inbox/zh-tw/christmas-messaging-no-conversation-chat-bg-v1.png";
import EmptyLiveList from "../../../../assets/background/shared/no-live-profiles.svg";
import EmptyLiveListAlt from "../../../../assets/background/shared/live-list-empty.svg";
import CompleteTorEn from "../../../../assets/background/search/en/preview_2.png";
import CompleteTorJp from "../../../../assets/background/search/ja/preview_2.png";
import CompleteTorZhTw from "../../../../assets/background/search/zh-tw/preview_2.png";
import CameraPermissionTutorial1En from "../../../../assets/background/private-call/en/permission-tutorial-1.png";
import CameraPermissionTutorial2En from "../../../../assets/background/private-call/en/permission-tutorial-2.png";
import CameraPermissionTutorial3En from "../../../../assets/background/private-call/en/permission-tutorial-3.png";
import CameraPermissionTutorial1ZhTw from "../../../../assets/background/private-call/zh-tw/permission-tutorial-1.png";
import CameraPermissionTutorial2ZhTw from "../../../../assets/background/private-call/zh-tw/permission-tutorial-2.png";
import CameraPermissionTutorial3ZhTw from "../../../../assets/background/private-call/zh-tw/permission-tutorial-3.png";
import LevelIntroMainEn from "../../../../assets/background/landing/en/intro.png";
import LevelIntroMainZhTw from "../../../../assets/background/landing/zh-tw/intro.png";
import LevelIntroPoint1En from "../../../../assets/background/landing/en/intro-img-1.png";
import LevelIntroPoint2En from "../../../../assets/background/landing/en/intro-img-2.png";
import OnboardingLivestreaming1En from "../../../../assets/background/live/en/intro.png";
import OnboardingLivestreaming2En from "../../../../assets/background/live/en/1.png";
import OnboardingLivestreaming3En from "../../../../assets/background/live/en/2.png";
import OnboardingLivestreaming4En from "../../../../assets/background/live/en/3.png";
import OnboardingLivestreaming5En from "../../../../assets/background/live/en/4.png";
import OboardingLivestreaming1Ja from "../../../../assets/background/live/ja/intro.png";
import OboardingLivestreaming2Ja from "../../../../assets/background/live/ja/1.png";
import OboardingLivestreaming3Ja from "../../../../assets/background/live/ja/2.png";
import OboardingLivestreaming4Ja from "../../../../assets/background/live/ja/3.png";
import OboardingLivestreaming5Ja from "../../../../assets/background/live/ja/4.png";
import OnboardingLivestreaming1ZhTw from "../../../../assets/background/live/zh-tw/intro.png";
import OnboardingLivestreaming2ZhTw from "../../../../assets/background/live/zh-tw/1.png";
import OnboardingLivestreaming3ZhTw from "../../../../assets/background/live/zh-tw/2.png";
import OnboardingLivestreaming4ZhTw from "../../../../assets/background/live/zh-tw/3.png";
import OnboardingLivestreaming5ZhTw from "../../../../assets/background/live/zh-tw/4.png";
import TorIntroEn from "../../../../assets/background/search/en/intro.jpg";
import TorIntroJp from "../../../../assets/background/search/ja/intro.jpg";
import TorIntroZhTw from "../../../../assets/background/search/zh-tw/intro.jpg";
import DiamondsIntro1En from "../../../../assets/background/streamer-center/en/diamonds-info-1.png";
import DiamondsIntro2En from "../../../../assets/background/streamer-center/en/diamonds-info-2.png";
import DiamondsIntro1ZhTw from "../../../../assets/background/streamer-center/zh-tw/diamonds-info-1.png";
import DiamondsIntro2ZhTw from "../../../../assets/background/streamer-center/zh-tw/diamonds-info-2.png";
import OnboardingVideoCallSb1En from "../../../../assets/background/private-call/en/onboarding-video-call-sb-1.png";
import OnboardingVideoCallSb2En from "../../../../assets/background/private-call/en/onboarding-video-call-sb-2.svg";
import OnboardingVideoCallSb1ZhTw from "../../../../assets/background/private-call/zh-tw/onboarding-video-call-sb-1.png";
import OnboardingVideoCallSb2ZhTw from "../../../../assets/background/private-call/zh-tw/onboarding-video-call-sb-2.png";
import OnboardingVideoCallQuickGiftEn from "../../../../assets/background/tutorial/video-call/en/vc-overlay-sb-quick-gift-request.jpg";
import OnboardingVideoCallMoreGiftEn from "../../../../assets/background/tutorial/video-call/en/vc-overlay-sb-more-gift-request.jpg";
import OnboardingVideoCallQuickGiftZhTw from "../../../../assets/background/tutorial/video-call/zh-tw/vc-overlay-sb-quick-gift-request.jpg";
import OnboardingVideoCallMoreGiftZhTw from "../../../../assets/background/tutorial/video-call/zh-tw/vc-overlay-sb-more-gift-request.jpg";
import LandscapeBackground from "../../../../assets/background/landscape/image-landscape.png";
import TryPwaEn from "../../../../assets/background/landing/en/try-pwa.png";
import TryPwaJp from "../../../../assets/background/landing/ja/try-pwa.png";
import TryPwaZhTw from "../../../../assets/background/landing/zh-tw/try-pwa.png";
import AddToHomescreenEn from "../../../../assets/background/landing/en/add-to-home-screen.png";
import AddToHomescreenJp from "../../../../assets/background/landing/ja/add-to-home-screen.png";
import AddToHomescreenZhTw from "../../../../assets/background/landing/zh-tw/add-to-home-screen.png";
import VideoCallSettingsTutorial1En from "../../../../assets/background/tutorial/video-call/en/videocall-settings-1b.png";
import VideoCallSettingsTutorial2En from "../../../../assets/background/tutorial/video-call/en/videocall-settings-2-sb.png";
import VcOverlayProfileEn from "../../../../assets/background/tutorial/video-call/en/vc-overlay-profile.jpg";
import VcOverlayProfileJp from "../../../../assets/background/tutorial/video-call/jp/vc-overlay-profile.jpg";
import VcOverlayProfileZhTw from "../../../../assets/background/tutorial/video-call/zh-tw/vc-overlay-profile.jpg";
import VcOverlayMessagingEn from "../../../../assets/background/tutorial/video-call/en/vc-overlay-messaging.jpg";
import VcOverlayMessagingJp from "../../../../assets/background/tutorial/video-call/jp/vc-overlay-messaging.jpg";
import VcOverlayMessagingZhTw from "../../../../assets/background/tutorial/video-call/zh-tw/vc-overlay-messaging.jpg";
import VcOverlayCustomizeMenuEn from "../../../../assets/background/tutorial/video-call/en/vc-overlay-sb-customize-menu.jpg";
import VcOverlayCustomizeMenuJp from "../../../../assets/background/tutorial/video-call/jp/vc-overlay-sb-customize-menu.jpg";
import VcOverlayCustomizeMenuZhTw from "../../../../assets/background/tutorial/video-call/zh-tw/vc-overlay-sb-customize-menu.jpg";
import VcOverlaySettingsEn from "../../../../assets/background/tutorial/video-call/en/vc-overlay-settings.jpg";
import VcOverlaySettingsJp from "../../../../assets/background/tutorial/video-call/jp/vc-overlay-settings.jpg";
import VcOverlaySettingsZhTw from "../../../../assets/background/tutorial/video-call/zh-tw/vc-overlay-settings.jpg";
import VcOverlayTipMenuEn from "../../../../assets/background/tutorial/video-call/en/vc-overlay-sb-tip-menu.jpg";
import VcOverlayTipMenuJp from "../../../../assets/background/tutorial/video-call/jp/vc-overlay-sb-tip-menu.jpg";
import VcOverlayTipMenuZhTw from "../../../../assets/background/tutorial/video-call/zh-tw/vc-overlay-sb-tip-menu.jpg";
import VpnBlockDesktopBackground from "../../../../assets/background/onboarding/vpn-block-desktop-background.png";
import EmailVerificationBackground from "../../../../assets/background/onboarding/email-verification-background.png";
import SugarbookVerifiedBackground from "../../../../assets/background/profile/sugarbook-verified-sample-selfie.png";
import SugarbookVerifiedApprovedBackgroundEn from "../../../../assets/background/profile/en/verified-approved-background.png";
import SugarbookVerifiedRejectedBackgroundEn from "../../../../assets/background/profile/en/verified-rejected-background.png";
import SugarbookVerifiedApprovedBackgroundZhTw from "../../../../assets/background/profile/zh-tw/verified-approved-background.png";
import SugarbookVerifiedRejectedBackgroundZhTw from "../../../../assets/background/profile/zh-tw/verified-rejected-background.png";
import TorCNYSelectedBackground from "../../../../assets/background/profile/tor-cny-selected-background.png";
import TorCnyUnselectedBackground from "../../../../assets/background/profile/tor-cny-unselected-background.png";
import RegistrationDesktopBackground from "../../../../assets/background/shared/registration-desktop-background.png";
import RegistrationMobileBackground from "../../../../assets/background/shared/registration-mobile-background.png";
import RegistrationLivestreamingPhoneOverlay from "../../../../assets/background/registration/registration-livestreaming-phone-overlay.png";
import LivenessGoodFitBackground from "../../../../assets/background/verification/liveness-good-fit-default.png";
import LivenessTooFarBackground from "../../../../assets/background/verification/liveness-too-far-default.png";
import LivenessGoodFitAsianBackground from "../../../../assets/background/verification/liveness-good-fit.png";
import LivenessTooFarAsianBackground from "../../../../assets/background/verification/liveness-too-far.png";
// Lottie
import GeneralSuccessLottie from "../../../../assets/lottie/general/general-success-lottie.json";
import GeneralErrorLottie from "../../../../assets/lottie/general/general-error-lottie.json";
import CasinoLeaderboardLottie from "../../../../assets/lottie/casino/casino-lottie.json";
// Video
import LowPowerModeVideo from "../../../../assets/video/low-power-video.mp4";
import TaiwanSplashScreenVideo from "../../../../assets/video/taiwan-splash-screen-video.mp4";
// Testing
import CasinoTempBackground from "../../../../assets/testing/casino-temp-background.png";
import ChipTempLogo from "../../../../assets/testing/chip-temp-logo.png";
import VipEntranceTest from "../../../../assets/testing/Entrance.gif";
// Redux
import { useSelector } from "react-redux";
// i18next
import { useTranslation } from "react-i18next";

const AssetManager = () => {
  // Redux variables
  const sugarbookTheme = useSelector((state) => state.app.sugarbookTheme);

  // i18next variables
  const { i18n } = useTranslation();

  const returnAsset = (asset) => {
    const assetMapping = {
      // Logo
      sugarbookLogo: getSugarbookLogo(),
      sugarbookLogoWithTagline: getSugarbookLogoWithTagline(),
      sugarbookLogoWithTaglineWhite: getSugarbookLogoWithTaglineWhite(),
      sugarbookAppLogo: SugarbookAppLogo,
      sugarbookWhiteLogo: SugarbookWhiteLogo,
      facebookLogo: FacebookLogo,
      visaLogo: Visa,
      mastercardLogo: MasterCard,
      maestroLogo: Maestro,
      visaElectronLogo: VisaElectron,
      bleueLogo: Bleue,
      dinersLogo: Diners,
      discoverLogo: Discover,
      jcbLogo: JCB,
      fpxLogo: FpxLogo,
      // Icon
      sugarbookIcon: SugarbookIcon,
      homeIcon: HomeIcon,
      homeIconSelected: HomeIconSelected,
      messagingIcon: MessagingIcon,
      messagingIconSelected: MessagingIconSelected,
      videoIcon: VideoIcon,
      videoIconSelected: VideoIconSelected,
      liveIcon: LiveIcon,
      liveIconSelected: LiveIconSelected,
      favoriteIcon: FavoriteIcon,
      favoriteIconSelected: FavoriteIconSelected,
      notificationIcon: NotificationIcon,
      emptyNotificationIcon: EmptyNotificationIcon,
      profileIcon: ProfileIcon,
      profileIconSelected: ProfileIconSelected,
      searchFilterIcon: SearchFilterIcon,
      searchFilteredIcon: SearchFilteredIcon,
      searchDesktopIcon: SearchDesktopIcon,
      messageDesktopIcon: MessageDesktopIcon,
      favoriteDesktopIcon: FavoriteDesktopIcon,
      videoCallDesktopIcon: VideoCallDesktopIcon,
      liveDesktopIcon: LiveDesktopIcon,
      leaderboardNewIcon: getLeaderboardIcon(),
      leaderboardIcon: LeaderboardIcon,
      sbCoinIcon: SBCoin,
      sbCoinTickIcon: SBCoinTick,
      sbDiamondIcon: SBDiamond,
      calendarIcon: CalendarIcon,
      settingsIcon: SettingsIcon,
      backIcon: BackIcon,
      backLightIcon: BackLightIcon,
      closeLightIcon: CloseLightIcon,
      infoIcon: InfoIcon,
      videoCallIcon: VideoCall,
      alertIcon: AlertIcon,
      blueTickIcon: BlueTick,
      greyTickIcon: GreyTick,
      redSettingsIcon: RedSettingIcon,
      redInfoIcon: RedInfoIcon,
      helpIcon: HelpIcon,
      pkIcon: PkLogo,
      pkVsIcon: PkVsIcon,
      leaderboardPkIcon: LeaderboardPkIcon,
      pkMedalIcon: PkMedal,
      defaultProfilePhotoIcon: DefaultPhoto,
      volumeUnmuteIcon: VolumeUnmute,
      volumeMuteIcon: VolumeMute,
      liveReportIcon: LiveReport,
      liveBackIcon: LiveBack,
      liveViewerIcon: LiveViewer,
      liveAddCoinIcon: LiveAddCoin,
      pinChatIcon: PinChatIcon,
      inReviewIcon: InReview,
      addPhotoIcon: AddBackground,
      uploadIcon: UploadIcon,
      profilePhotoIcon: PhotoIcons,
      profileAboutMeIcon: AboutMeIcon,
      profileAchievementIcon: AchievementIcon,
      privatePhotoLockIcon: PrivatePhotoLockIcon,
      premiumLockIcon: PremiumLockIcon,
      userProfileCompleteIcon: UserProfileCompleteIcon,
      mailIcon: MailFillIcon,
      videoCallUnavailableIcon: VideoCallUnavailable,
      closeIcon: CloseIcon,
      deleteIcon: DeleteIcon,
      blockedMembersIcon: BlockedMembersIcon,
      deactivateAccountIcon: DeactivateIcon,
      locationPermissionIcon: LocationPermissionIcon,
      locationPermissionDeniedIcon: LocationPermissionDeniedIcon,
      premiumUnlockIcon: PremiumUnlockIcon,
      shareIcon: ShareIcon,
      playOutlineIcon: PlayOutlineIcon,
      actionAlertIcon: ActionAlertIcon,
      actionSuccessIcon: ActionSuccessIcon,
      dropPhotoIcon: DropPhotoIcon,
      lowPowerIcon: LowPowerIcon,
      notificationPermissionIcon: getNotificationPermissionIcon(),
      paymentErrorIcon: PaymentErrorIcon,
      paymentWarningIcon: PaymentWarningIcon,
      rotateIcon: FlipIcon,
      closeProfileIcon: CloseProfileIcon,
      deleteProfileIcon: DeleteProfileIcon,
      rejectedProfileIcon: RejectedProfileIcon,
      profileMessageIcon: ProfileMessageIcon,
      profileVideoCallIcon: ProfileVideoCallIcon,
      viewProfileIcon: ViewProfileIcon,
      avatarFallbackMan: AvatarFallbackMan,
      avatarFallbackWoman: AvatarFallbackWoman,
      avatarFallbackNeutral: AvatarFallbackNeutral,
      photoPermissionEmptyIcon: PhotoPermissionEmptyIcon,
      privateStandbyIcon: PrivateStandbyIcon,
      goldLockIcon: GoldLockIcon,
      messageRedIcon: MessageRedIcon,
      messageWhiteIcon: MessageWhiteIcon,
      favoriteRedIcon: FavoriteRedIcon,
      favoriteWhiteIcon: FavoriteWhiteIcon,
      favoriteWhiteAltIcon: FavoriteWhiteAltIcon,
      favoritedRedIcon: FavoritedRedIcon,
      favoritedUserCardIcon: FavoritedUserCardIcon,
      videoCallRedIcon: VideoCallRedIcon,
      messagingOutlineIcon: MessagingOutlineIcon,
      viewedMeOutlineIcon: ViewedMeOutlineIcon,
      lockWhiteIcon: LockWhiteIcon,
      chargedMessageEnableIcon: ChargedMessageEnableIcon,
      chargedMessageDisableIcon: ChargedMessageDisableIcon,
      resetPermissionIcon: ResetPermissionIcon,
      standbyKickIcon: StandbyKickIcon,
      endCallIcon: EndCallIcon,
      videoCallMoreIcon: VideoCallMoreIcon,
      mailOutlineIcon: MailOutlineIcon,
      priceTagIcon: PriceTagIcon,
      creditCardOutlineIcon: CreditCardOutlineIcon,
      worldOutlineIcon: WorldOutlineIcon,
      upgradePremiumIcon: UpgradePremiumIcon,
      upgradeStarIcon: UpgradeStarIcon,
      paymentErrorAltIcon: PaymentErrorAltIcon,
      callSentIcon: CallSentIcon,
      videoCallAvailableIcon: VideoCallAvailableIcon,
      videoCallMissedIcon: VideoCallMissedIcon,
      paymentSecureLockIcon: PaymentSecureLockIcon,
      paymentSecureShieldIcon: PaymentSecureShieldIcon,
      pauseIcon: PauseIcon,
      playIcon: PlayIcon,
      feedLikeIcon: FeedLikeIcon,
      feedSendTipIcon: FeedSendTipIcon,
      feedMessageIcon: FeedMessageIcon,
      goLivePlayIcon: GoLivePlayIcon,
      pinLocationIcon: PinLocationIcon,
      pictureFrameIcon: PictureFrameIcon,
      priceTagProfileIcon: PriceTagProfileIcon,
      userIcon: UserIcon,
      descriptionIcon: DescriptionIcon,
      bankIcon: BankIcon,
      profileSmileyIcon: ProfileSmileyIcon,
      materialLockIcon: MaterialLockIcon,
      cameraBlueOutlineIcon: CameraBlueOutlineIcon,
      shieldCheckOutlineIcon: ShieldCheckOutlineIcon,
      safetyOutlineIcon: SafetyOutlineIcon,
      redCautionIcon: RedCautionIcon,
      signupBackIcon: SignupBackIcon,
      blueBulbIcon: BlueBulbIcon,
      leaderboardCasinoChips: LeaderboardCasinoChips,
      casinoGameIcon: CasinoGameIcon,
      diceIcon: DiceIcon,
      threeAceIcon: ThreeAceIcon,
      torCnyIcon: TorCnyIcon,
      dualPkCountdown1Icon: DualPkCountdown1Icon,
      dualPkCountdown2Icon: DualPkCountdown2Icon,
      dualPkCountdown3Icon: DualPkCountdown3Icon,
      dualPkRound1Icon: DualPkRound1Icon,
      dualPkRound2Icon: DualPkRound2Icon,
      dualPkRound3Icon: DualPkRound3Icon,
      dualPkRound4Icon: DualPkRound4Icon,
      dualPkRound5Icon: DualPkRound5Icon,
      dualPkFinalRoundIcon: DualPkFinalRoundIcon,
      dualPkDrawRoundIcon: DualPkDrawRoundIcon,
      dualPkWinnerIcon: DualPkWinnerIcon,
      dualPkDrawEmojiIcon: DualPkDrawEmojiIcon,
      dualPkWinEmojiIcon: DualPkWinEmojiIcon,
      dualPkLoseEmojiIcon: DualPkLoseEmojiIcon,
      dualPkLoseIcon: DualPkLoseIcon,
      dualPkWinIcon: DualPkWinIcon,
      dualPkFirstPlaceIcon: DualPkFirstPlaceIcon,
      dualPkSecondPlaceIcon: DualPkSecondPlaceIcon,
      dualPkThirdPlaceIcon: DualPkThirdPlaceIcon,
      mobileCallingIcon: MobileCallingIcon,
      switchIcon: SwitchIcon,
      lightBulbIcon: LightBulbIcon,
      registrationSearchIcon: RegistrationSearchIcon,
      registrationIncomeIcon: RegistrationIncomeIcon,
      registrationNetworthIcon: RegistrationNetworthIcon,
      registrationLifestyleIcon: RegistrationLifestyleIcon,
      registrationDatingBudgetIcon: RegistrationDatingBudgetIcon,
      registrationRelationshipStatusIcon: RegistrationRelationshipStatusIcon,
      registrationOccupationIcon: RegistrationOccupationIcon,
      registrationDatingStylesIcon: RegistrationDatingStylesIcon,
      registrationFantasiesIcon: RegistrationFantasiesIcon,
      registrationEthnicityIcon: RegistrationEthnicityIcon,
      registrationSmokeIcon: RegistrationSmokeIcon,
      registrationDrinkIcon: RegistrationDrinkIcon,
      registrationAlcoholIcon: RegistrationAlcoholIcon,
      registrationAboutIcon: RegistrationAboutIcon,
      registrationLookingForIcon: RegistrationLookingForIcon,
      registrationLivestreamingIcon: RegistrationLivestreamingIcon,
      registrationPhotoIcon: RegistrationPhotoIcon,
      registrationUploadPhotoIcon: RegistrationUploadPhotoIcon,
      restartJourneyIcon: RestartJourneyIcon,
      seriousVibesIcon: SeriousVibesIcon,
      iceBreakerIcon: IceBreakerIcon,
      standOutIcon: StandOutIcon,
      redWineIcon: RedWineIcon,
      whiteWineIcon: WhiteWineIcon,
      champagneIcon: ChampagneIcon,
      cognacIcon: CognacIcon,
      tequilaIcon: TequilaIcon,
      beerIcon: BeerIcon,
      cocktailIcon: CocktailIcon,
      vodkaIcon: VodkaIcon,
      brandyIcon: BrandyIcon,
      highballIcon: HighballIcon,
      whiskeyIcon: WhiskeyIcon,
      liqueurIcon: LiqueurIcon,
      absintheIcon: AbsintheIcon,
      rumIcon: RumIcon,
      sangriaIcon: SangriaIcon,
      ginIcon: GinIcon,
      sakeIcon: SakeIcon,
      sojuIcon: SojuIcon,
      martiniIcon: MartiniIcon,
      shotsIcon: ShotsIcon,
      heartFilledIcon: HeartFilledIcon,
      messageReadIcon: MessageReadIcon,
      profileViewIcon: ProfileViewIcon,
      profileApprovalIcon: ProfileApprovalIcon,
      unlimitedMessagesIcon: UnlimitedMessagesIcon,
      userSecretIcon: UserSecretIcon,
      locationSharpIcon: LocationSharpIcon,
      calendarSlashIcon: CalendarSlashIcon,
      premiumStarIcon: PremiumStarIcon,
      whiteGiftIcon: WhiteGiftIcon,
      checkSquareIcon: CheckSquareIcon,
      whiteLoaderIcon: WhiteLoaderIcon,
      whiteLockIcon: WhiteLockIcon,
      whiteSearchFilterIcon: WhiteSearchFilterIcon,
      personalizedAboutMeIcon: PersonalizedAboutMeIcon,
      lifestyleBudgetIcon: LifestyleBudgetIcon,
      datingBudgetIcon: DatingBudgetIcon,
      networthIcon: NetworthIcon,
      annualIncomeIcon: AnnualIncomeIcon,
      heightIcon: HeightIcon,
      bodyTypeIcon: BodyTypeIcon,
      ethnicityIcon: EthnicityIcon,
      eyeColorIcon: EyeColorIcon,
      hairColorIcon: HairColorIcon,
      occupationIcon: OccupationIcon,
      educationIcon: EducationIcon,
      relationshipIcon: RelationshipIcon,
      kidsIcon: KidsIcon,
      smokingIcon: SmokingIcon,
      drinkingIcon: DrinkingIcon,
      personalityTypeIcon: PersonalityTypeIcon,
      starSignIcon: StarSignIcon,
      personalityTraitsIcon: PersonalityTraitsIcon,
      dominantSubmissiveIcon: DominantSubmissiveIcon,
      fitnessIcon: FitnessIcon,
      hobbiesIcon: HobbiesIcon,
      editAboutMeIcon: EditAboutMeIcon,
      profileRoleIcon: ProfileRoleIcon,
      profileLookingForIcon: ProfileLookingForIcon,
      sensitiveIcon: SensitiveIcon,
      masqueradeMaskIcon: MasqueradeMaskIcon,
      lgbtqIcon: LgbtqIcon,
      tooFarCrossIcon: TooFarCrossIcon,
      goodFitTickIcon: GoodFitTickIcon,
      greenFilledTickIcon: GreenFilledTickIcon,
      cryptocurrencyIcon: CryptocurrencyIcon,
      creditCardIcon: CreditCardIcon,
      // Badge
      freePremiumBadge: getPremiumBadge(),
      premiumBadge: getPremiumBadge(),
      diamondBadge: getDiamondBadge(),
      adminBadge: getAdminBadge(),
      premiumMiniBadge: PremiumMiniBadge,
      verifiedProfileBadge: VerifiedProfile,
      unverifiedProfileBadge: UnverifiedProfile,
      verifiedProfileOutlineBadge: VerifiedProfileOutline,
      verifiedCallerBadge: VerifiedCaller,
      moreGiftBadge: getMoreGiftBadge(),
      sendMessageLightIcon: SendMessageIcon,
      sendMessageDarkIcon: SendMessageDarkIcon,
      leaderboardRank1: BadgeRank1,
      leaderboardRank2: BadgeRank2,
      leaderboardRank3: BadgeRank3,
      newBadge: NewBadge,
      superOfferBadge: getSuperOfferBadge(),
      megaOfferBadge: getMegaOfferBadge(),
      privateRoomBadge: getPrivateRoomIcon(),
      tier1Badge: Tier1Badge,
      tier2Badge: Tier2Badge,
      tier3Badge: Tier3Badge,
      level1: Level1,
      level12: Level12,
      level23: Level23,
      level34: Level34,
      level45: Level45,
      level55: Level55,
      trophyRank1: TrophyRank1,
      trophyRank2: TrophyRank2,
      trophyRank3: TrophyRank3,
      discreteBadge: DiscreteBadge,
      securityBadge: SecurityBadge,
      sslBadge: SslBadge,
      payWithCryptoBadge: PayWithCryptoBadge,
      // Image
      tipCoins: TipCoins,
      tipCoinsAccepted: TipCoinsAccepted,
      chargedMessageSbPopupEnable: ChargedMessageSbPopupEnable,
      chargedMessageSbPopupDisable: ChargedMessageSbPopupDisable,
      chargedMessageSdPopup: ChargedMessageSdPopup,
      sbPrivateRoomWarningImage: getSbPrivateRoomWarningImage(),
      thankYouVIPImage: ThankYouVIPImage,
      // Emoji
      blowKissEmoji: BlowKissEmoji,
      heartEyesEmoji: HeartEyesEmoji,
      thumbsUpEmoji: ThumbsUpEmoji,
      fireEmoji: FireEmoji,
      // Gif
      callRequestGif: CallRequestGif,
      callAcceptGif: CallAcceptGif,
      callRejectGif: CallRejectGif,
      callWaitingGif: CallWaitingGif,
      checkRedGif: CheckRedGif,
      // Background
      christmasDesktopBackground: ChristmasDesktopBackground,
      christmasMobileBackground: ChristmasMobileBackground,
      christmasMobileBackgroundV2: ChristmasMobileBackgroundV2,
      coinShopBackground: CoinShopBackground,
      messagingStartBackground: getMessagingStartBackground(),
      emptyLiveList: EmptyLiveList,
      emptyLiveListAlt: EmptyLiveListAlt,
      completeTorBackground: getCompleteTorBackgroundImage(),
      cameraPermissionTutorial1: getCameraPermissionTutorial1(),
      cameraPermissionTutorial2: getCameraPermissionTutorial2(),
      cameraPermissionTutorial3: getCameraPermissionTutorial3(),
      levelIntroMain: getLevelIntroImage(),
      levelIntroPoint1: LevelIntroPoint1En,
      levelIntroPoint2: LevelIntroPoint2En,
      onboardingLive1Background: getOnboardingLivestreaming1(),
      onboardingLive2Background: getOnboardingLivestreaming2(),
      onboardingLive3Background: getOnboardingLivestreaming3(),
      onboardingLive4Background: getOnboardingLivestreaming4(),
      onboardingLive5Background: getOnboardingLivestreaming5(),
      torIntroBackground: getTorIntroBackground(),
      diamondsIntro1Background: getDiamondsIntro1Background(),
      diamondsIntro2Background: getDiamondsIntro2Background(),
      onboardingVideoCallSb1Background: getOnboardingVideoCallSb1(),
      onboardingVideoCallSb2Background: getOnboardingVideoCallSb2(),
      onboardingVideoCallSd2Background: getOnboardingVideoCallSd2(),
      onboardingVideoCallQuickGiftBackground: getOnboardingVideoCallQuickGift(),
      onboardingVideoCallMoreGiftBackground: getOnboardingVideoCallMoreGift(),
      landscapeBackground: LandscapeBackground,
      tryPwaBackground: getTryPwaBackground(),
      pwaTutorialBackground: getPwaTutorialBackground(),
      videoCallSettingsTutorial1Background: VideoCallSettingsTutorial1En,
      videoCallSettingsTutorial2Background: VideoCallSettingsTutorial2En,
      vcOverlayProfileBackground: getVcOverlayProfile(),
      vcOverlayMessagingBackground: getVcOverlayMessaging(),
      vcOverlayCustomizeMenuBackground: getVcOverlayCustomizeMenu(),
      vcOverlaySettingsBackground: getVcOverlaySettings(),
      vcOverlayTipMenuBackground: getVcOverlayTipMenu(),
      vpnBlockDesktopBackground: VpnBlockDesktopBackground,
      emailVerificationBackground: EmailVerificationBackground,
      sugarbookVerifiedBackground: SugarbookVerifiedBackground,
      sugarbookVerifiedApprovedBackground:
        getSugarbookVerifiedApprovedBackground(),
      sugarbookVerifiedRejectedBackground:
        getSugarbookVerifiedRejectedBackground(),
      torCNYSelectedBackground: TorCNYSelectedBackground,
      torCnyUnselectedBackground: TorCnyUnselectedBackground,
      casinoTempBackground: CasinoTempBackground,
      chipTempLogo: ChipTempLogo,
      registrationDesktopBackground: RegistrationDesktopBackground,
      registrationMobileBackground: RegistrationMobileBackground,
      registrationLivestreamingPhoneOverlay:
        RegistrationLivestreamingPhoneOverlay,
      livenessGoodFitBackground: getLivenessGoodFitBackground(),
      livenessTooFarBackground: getLivenessTooFarBackground(),
      // Lottie
      generalSuccessLottie: GeneralSuccessLottie,
      generalErrorLottie: GeneralErrorLottie,
      casinoLeaderboardLottie: CasinoLeaderboardLottie,
      // Video
      lowPowerModeVideo: LowPowerModeVideo,
      taiwanSplashScreenVideo: TaiwanSplashScreenVideo,
      // Testing
      vipEntranceTest: VipEntranceTest,
      // Template
      template: {
        default: null,
      },
    };

    return assetMapping[asset] || null;
  };

  // Utility Functions
  const getSugarbookLogo = () => {
    switch (sugarbookTheme) {
      case utilityConst.sugarbookTheme.christmas:
        return SugarbookChristmasLogo;
      case utilityConst.sugarbookTheme.cny:
        return SugarbookCNYLogo;
      case utilityConst.sugarbookTheme.valentine:
        return SugarbookValentineLogo;
      case utilityConst.sugarbookTheme.raya:
        return SugarbookRayaLogo;
      case utilityConst.sugarbookTheme.anniversary:
      case utilityConst.sugarbookTheme.default:
      default:
        return SugarbookLogo;
    }
  };
  const getSugarbookLogoWithTagline = () => {
    switch (sugarbookTheme) {
      case utilityConst.sugarbookTheme.christmas:
        if (i18n.language.toLowerCase().includes("zh-tw")) {
          return SugarbookChristmasTaglineLogoZh;
        } else if (i18n.language.toLowerCase().includes("ja")) {
          return SugarbookChristmasTaglineLogoJa;
        }

        return SugarbookChristmasTaglineLogoEn;
      case utilityConst.sugarbookTheme.cny:
        if (i18n.language.toLowerCase().includes("zh-tw")) {
          return SugarbookCNYTaglineLogoZh;
        } else if (i18n.language.toLowerCase().includes("ja")) {
          return SugarbookCNYTaglineLogoJa;
        }

        return SugarbookCNYTaglineLogoEn;
      case utilityConst.sugarbookTheme.valentine:
        if (i18n.language.toLowerCase().includes("zh-tw")) {
          return SugarbookValentineTaglineLogoZh;
        } else if (i18n.language.toLowerCase().includes("ja")) {
          return SugarbookValentineTaglineLogoJa;
        }

        return SugarbookValentineTaglineLogoEn;
      case utilityConst.sugarbookTheme.raya:
        if (i18n.language.toLowerCase().includes("zh-tw")) {
          return SugarbookRayaTaglineLogoZh;
        } else if (i18n.language.toLowerCase().includes("ja")) {
          return SugarbookRayaTaglineLogoJa;
        }

        return SugarbookRayaTaglineLogoEn;
      case utilityConst.sugarbookTheme.anniversary:
        if (i18n.language.toLowerCase().includes("zh-tw")) {
          return SugarbookTaglineLogoZh;
        } else if (i18n.language.toLowerCase().includes("ja")) {
          return SugarbookTaglineLogoJa;
        }

        return SugarbookTaglineLogoEn;
      case utilityConst.sugarbookTheme.default:
      default:
        if (i18n.language.toLowerCase().includes("zh-tw")) {
          return SugarbookTaglineLogoZh;
        } else if (i18n.language.toLowerCase().includes("ja")) {
          return SugarbookTaglineLogoJa;
        }

        return SugarbookTaglineLogoEn;
    }
  };
  const getSugarbookLogoWithTaglineWhite = () => {
    switch (sugarbookTheme) {
      case utilityConst.sugarbookTheme.christmas:
        if (i18n.language.toLowerCase().includes("zh-tw")) {
          return SugarbookChristmasTaglineWhiteLogoZh;
        } else if (i18n.language.toLowerCase().includes("ja")) {
          return SugarbookChristmasTaglineWhiteLogoJa;
        }

        return SugarbookChristmasTaglineWhiteLogoEn;
      case utilityConst.sugarbookTheme.cny:
        if (i18n.language.toLowerCase().includes("zh-tw")) {
          return SugarbookCNYTaglineWhiteLogoZh;
        } else if (i18n.language.toLowerCase().includes("ja")) {
          return SugarbookCNYTaglineWhiteLogoJa;
        }

        return SugarbookCNYTaglineWhiteLogoEn;
      case utilityConst.sugarbookTheme.valentine:
        if (i18n.language.toLowerCase().includes("zh-tw")) {
          return SugarbookValentineTaglineWhiteLogoZh;
        } else if (i18n.language.toLowerCase().includes("ja")) {
          return SugarbookValentineTaglineWhiteLogoJa;
        }

        return SugarbookValentineTaglineWhiteLogoEn;
      case utilityConst.sugarbookTheme.raya:
        if (i18n.language.toLowerCase().includes("zh-tw")) {
          return SugarbookRayaTaglineWhiteLogoZh;
        } else if (i18n.language.toLowerCase().includes("ja")) {
          return SugarbookRayaTaglineWhiteLogoJa;
        }

        return SugarbookRayaTaglineWhiteLogoEn;
      case utilityConst.sugarbookTheme.anniversary:
        if (i18n.language.toLowerCase().includes("zh-tw")) {
          return SugarbookTaglineWhiteLogoZh;
        } else if (i18n.language.toLowerCase().includes("ja")) {
          return SugarbookTaglineWhiteLogoJa;
        }

        return SugarbookTaglineWhiteLogoEn;
      case utilityConst.sugarbookTheme.default:
      default:
        if (i18n.language.toLowerCase().includes("zh-tw")) {
          return SugarbookTaglineWhiteLogoZh;
        } else if (i18n.language.toLowerCase().includes("ja")) {
          return SugarbookTaglineWhiteLogoJa;
        }

        return SugarbookTaglineWhiteLogoEn;
    }
  };
  const getMessagingStartBackground = () => {
    switch (sugarbookTheme) {
      case utilityConst.sugarbookTheme.christmas:
        if (i18n.language.toLowerCase().includes("zh-tw")) {
          return ChristmasMessagingStartBackgroundZhTw;
        } else if (i18n.language.toLowerCase().includes("ja")) {
          return ChristmasMessagingStartBackgroundJa;
        }

        return ChristmasMessagingStartBackgroundEn;
      case utilityConst.sugarbookTheme.anniversary:
        if (i18n.language.toLowerCase().includes("zh-tw")) {
          return MessagingStartBackgroundEn;
        } else if (i18n.language.toLowerCase().includes("ja")) {
          return MessagingStartBackgroundEn;
        }

        return MessagingStartBackgroundEn;
      case utilityConst.sugarbookTheme.valentine:
      case utilityConst.sugarbookTheme.raya:
      case utilityConst.sugarbookTheme.default:
      default:
        if (i18n.language.toLowerCase().includes("zh-tw")) {
          return MessagingStartBackgroundZhTw;
        } else if (i18n.language.toLowerCase().includes("ja")) {
          return MessagingStartBackgroundEn;
        }

        return MessagingStartBackgroundEn;
    }
  };
  const getLeaderboardIcon = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return LeaderboardNewIconZhTw;
    } else if (i18n.language.toLowerCase().includes("ja")) {
      return LeaderboardNewIconJa;
    }

    return LeaderboardNewIconEn;
  };
  const getFreePremiumBadge = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return FreePremiumBadgeZhTw;
    } else if (i18n.language.toLowerCase().includes("ja")) {
      return FreePremiumBadgeZhTw;
    }

    return FreePremiumBadgeZhTw;
  };
  const getPremiumBadge = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return PremiumBadgeZhTw;
    } else if (i18n.language.toLowerCase().includes("ja")) {
      return PremiumBadgeJp;
    }

    return PremiumBadgeEn;
  };
  const getDiamondBadge = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return DiamondBadgeZhTw;
    } else if (i18n.language.toLowerCase().includes("ja")) {
      return DiamondBadgeZhTw;
    }

    return DiamondBadgeZhTw; // Change to en
  };
  const getAdminBadge = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return AdminBadgeZhTw;
    } else if (i18n.language.toLowerCase().includes("ja")) {
      return AdminBadgeZhTw;
    }

    return AdminBadgeEn;
  };
  const getMoreGiftBadge = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return MoreGiftsZh;
    } else if (i18n.language.toLowerCase().includes("ja")) {
      return MoreGiftsJp;
    }

    return MoreGiftsEn;
  };
  const getSuperOfferBadge = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return SuperOfferZh;
    } else if (i18n.language.toLowerCase().includes("ja")) {
      return SuperOfferJp;
    }

    return SuperOfferEn;
  };
  const getMegaOfferBadge = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return MegaOfferZh;
    } else if (i18n.language.toLowerCase().includes("ja")) {
      return MegaOfferJp;
    }

    return MegaOfferEn;
  };
  const getPrivateRoomIcon = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return PrivateRoomIconZhTw;
    } else if (i18n.language.toLowerCase().includes("ja")) {
      return PrivateRoomIconEn;
    }

    return PrivateRoomIconEn;
  };
  const getCompleteTorBackgroundImage = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return CompleteTorZhTw;
    } else if (i18n.language.toLowerCase().includes("ja")) {
      return CompleteTorJp;
    }

    return CompleteTorEn;
  };
  const getLevelIntroImage = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return LevelIntroMainZhTw;
    } else if (i18n.language.toLowerCase().includes("ja")) {
      return LevelIntroMainZhTw;
    }

    return LevelIntroMainEn;
  };
  const getOnboardingLivestreaming1 = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return OnboardingLivestreaming1ZhTw;
    } else if (i18n.language.toLowerCase().includes("ja")) {
      return OboardingLivestreaming1Ja;
    }

    return OnboardingLivestreaming1En;
  };
  const getOnboardingLivestreaming2 = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return OnboardingLivestreaming2ZhTw;
    } else if (i18n.language.toLowerCase().includes("ja")) {
      return OboardingLivestreaming2Ja;
    }

    return OnboardingLivestreaming2En;
  };
  const getOnboardingLivestreaming3 = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return OnboardingLivestreaming3ZhTw;
    } else if (i18n.language.toLowerCase().includes("ja")) {
      return OboardingLivestreaming3Ja;
    }

    return OnboardingLivestreaming3En;
  };
  const getOnboardingLivestreaming4 = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return OnboardingLivestreaming4ZhTw;
    } else if (i18n.language.toLowerCase().includes("ja")) {
      return OboardingLivestreaming4Ja;
    }

    return OnboardingLivestreaming4En;
  };
  const getOnboardingLivestreaming5 = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return OnboardingLivestreaming5ZhTw;
    } else if (i18n.language.toLowerCase().includes("ja")) {
      return OboardingLivestreaming5Ja;
    }

    return OnboardingLivestreaming5En;
  };
  const getTorIntroBackground = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return TorIntroZhTw;
    } else if (i18n.language.toLowerCase().includes("ja")) {
      return TorIntroJp;
    }

    return TorIntroEn;
  };
  const getDiamondsIntro1Background = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return DiamondsIntro1ZhTw;
    } else if (i18n.language.toLowerCase().includes("ja")) {
      return DiamondsIntro1ZhTw;
    }

    return DiamondsIntro1En;
  };
  const getDiamondsIntro2Background = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return DiamondsIntro2ZhTw;
    } else if (i18n.language.toLowerCase().includes("ja")) {
      return DiamondsIntro2ZhTw;
    }

    return DiamondsIntro2En;
  };
  const getOnboardingVideoCallSb1 = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return OnboardingVideoCallSb1ZhTw;
    }

    return OnboardingVideoCallSb1En;
  };
  const getOnboardingVideoCallSb2 = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return OnboardingVideoCallSb2ZhTw;
    }

    return OnboardingVideoCallSb2En;
  };
  const getOnboardingVideoCallSd2 = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return OnboardingVideoCallSb2ZhTw;
    }

    return OnboardingVideoCallSb2En;
  };
  const getOnboardingVideoCallQuickGift = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return OnboardingVideoCallQuickGiftZhTw;
    }

    return OnboardingVideoCallQuickGiftEn;
  };
  const getOnboardingVideoCallMoreGift = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return OnboardingVideoCallMoreGiftZhTw;
    }

    return OnboardingVideoCallMoreGiftEn;
  };
  const getSbPrivateRoomWarningImage = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return SbPrivateRoomWarningImageZhTw;
    }

    return SbPrivateRoomWarningImageEn;
  };
  const getTryPwaBackground = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return TryPwaZhTw;
    } else if (i18n.language.toLowerCase().includes("ja")) {
      return TryPwaJp;
    }

    return TryPwaEn;
  };
  const getPwaTutorialBackground = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return AddToHomescreenZhTw;
    } else if (i18n.language.toLowerCase().includes("ja")) {
      return AddToHomescreenJp;
    }

    return AddToHomescreenEn;
  };
  const getVcOverlayProfile = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return VcOverlayProfileZhTw;
    } else if (i18n.language.toLowerCase().includes("ja")) {
      return VcOverlayProfileJp;
    }

    return VcOverlayProfileEn;
  };
  const getVcOverlayMessaging = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return VcOverlayMessagingZhTw;
    } else if (i18n.language.toLowerCase().includes("ja")) {
      return VcOverlayMessagingJp;
    }

    return VcOverlayMessagingEn;
  };
  const getVcOverlayCustomizeMenu = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return VcOverlayCustomizeMenuZhTw;
    } else if (i18n.language.toLowerCase().includes("ja")) {
      return VcOverlayCustomizeMenuJp;
    }

    return VcOverlayCustomizeMenuEn;
  };
  const getVcOverlaySettings = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return VcOverlaySettingsZhTw;
    } else if (i18n.language.toLowerCase().includes("ja")) {
      return VcOverlaySettingsJp;
    }

    return VcOverlaySettingsEn;
  };
  const getVcOverlayTipMenu = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return VcOverlayTipMenuZhTw;
    } else if (i18n.language.toLowerCase().includes("ja")) {
      return VcOverlayTipMenuJp;
    }

    return VcOverlayTipMenuEn;
  };
  const getNotificationPermissionIcon = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return NotificationPermissionIconZhTw;
    } else if (i18n.language.toLowerCase().includes("ja")) {
      return NotificationPermissionIconEn;
    }

    return NotificationPermissionIconEn;
  };
  const getSugarbookVerifiedApprovedBackground = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return SugarbookVerifiedApprovedBackgroundZhTw;
    }

    return SugarbookVerifiedApprovedBackgroundEn;
  };
  const getSugarbookVerifiedRejectedBackground = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return SugarbookVerifiedRejectedBackgroundZhTw;
    }

    return SugarbookVerifiedRejectedBackgroundEn;
  };
  const getCameraPermissionTutorial1 = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return CameraPermissionTutorial1ZhTw;
    } else if (i18n.language.toLowerCase().includes("ja")) {
      return CameraPermissionTutorial1En;
    }

    return CameraPermissionTutorial1En;
  };
  const getCameraPermissionTutorial2 = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return CameraPermissionTutorial2ZhTw;
    } else if (i18n.language.toLowerCase().includes("ja")) {
      return CameraPermissionTutorial2En;
    }

    return CameraPermissionTutorial2En;
  };
  const getCameraPermissionTutorial3 = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return CameraPermissionTutorial3ZhTw;
    } else if (i18n.language.toLowerCase().includes("ja")) {
      return CameraPermissionTutorial3En;
    }

    return CameraPermissionTutorial3En;
  };
  const getLivenessGoodFitBackground = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return LivenessGoodFitAsianBackground;
    }

    return LivenessGoodFitBackground;
  };
  const getLivenessTooFarBackground = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return LivenessTooFarAsianBackground;
    }

    return LivenessTooFarBackground;
  };

  return returnAsset;
};

export default AssetManager;
