// General
import "./coins-payment-confirmed-element.scss";
// Static Data
import routeConst from "../../../const/routeConst";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../utility/manager/icon-manager/icon-manager";

const CoinsPaymentConfirmedElement = (props) => {
  const { notificationId, coinsAmount, isSeen, timestamp } = props;

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Event Handlers | Button
  const onRedirectToWallet = () => {
    onNavigate(routeConst.profile.wallet.path);
  };

  return (
    <div
      id="coins-payment-confirmed-element-subcomponent"
      className={isSeen ? "" : "notification-unseen"}
      onClick={onRedirectToWallet}
    >
      <div className="payment-icon-container">
        {getIcon("sbCoinIcon", "payment-icon")}
        {getIcon("greenFilledTickIcon", "payment-confirmed-icon")}
      </div>

      <div className="coins-payment-body-container">
        <Trans
          i18nKey={"notification.coins_payment_confirmed"}
          values={{ coins: coinsAmount?.toLocaleString() }}
          components={{ b: <b /> }}
        />{" "}
        <span className="timestamp">{timestamp}</span>
      </div>
    </div>
  );
};

export default CoinsPaymentConfirmedElement;
