// General
import "./private-call-suspend-dialog.scss";
import { useEffect, forwardRef } from "react";
// Services
import { useLazyGetProfileQuery } from "../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateSuspendedInterval,
  updateSuspendedSeconds,
  startSuspendedSeconds,
  clearSuspendedTimer,
} from "../../../../redux/store/privateCallStore";
import { updatePrivateCallSuspendDialog } from "../../../../redux/store/dialogStore";
// Material UI
import { Dialog, Slide, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
// i18next
import { useTranslation } from "react-i18next";
// Moment
import moment from "moment";

const PrivateCallSuspendDialog = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // Redux variables
  const privateCallSuspendDialog = useSelector(
    (state) => state.dialog.privateCallSuspendDialog
  );
  const suspendedObj = useSelector((state) => state.privateCall.suspendedObj);
  const suspendedInterval = useSelector(
    (state) => state.privateCall.suspendedInterval
  );
  const suspendedSeconds = useSelector(
    (state) => state.privateCall.suspendedSeconds
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 850px)");

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    if (privateCallSuspendDialog) {
      getProfile(null, true);
    } else {
      dispatch(clearSuspendedTimer());
    }
  }, [privateCallSuspendDialog]);

  // Lifecycle | Check for update | privateCallSuspendDialog & suspendedObj
  useEffect(() => {
    if (privateCallSuspendDialog && suspendedObj?.suspension_until_epoch) {
      computeSuspensionTime(suspendedObj?.suspension_until_epoch);
    }
  }, [privateCallSuspendDialog, suspendedObj]);

  // Event Handlers | Dialog
  const onCloseDialog = () => {
    dispatch(updatePrivateCallSuspendDialog(false));
  };

  // Utility Functions
  const computeSuspensionTime = (epoch) => {
    if (suspendedInterval) return;

    const currentDate = moment();
    const expiryDate = moment.unix(epoch);
    const diffInSeconds = expiryDate.diff(currentDate, "seconds");

    dispatch(updateSuspendedSeconds(diffInSeconds));
    dispatch(
      updateSuspendedInterval(
        setInterval(() => {
          dispatch(startSuspendedSeconds());
        }, 1000)
      )
    );
  };
  const getSuspensionCopywriting = (seconds) => {
    const duration = moment.duration(seconds, "seconds");
    const minutes = duration.minutes();
    const remainingSeconds = duration.seconds();
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <Dialog
      className={`${!isMobile ? "custom-radius35-dialog" : ""}`}
      fullScreen={isMobile}
      open={privateCallSuspendDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={onCloseDialog}
    >
      <div
        id="private-call-suspend-dialog"
        className={`${!isMobile ? "max-width-container" : ""}`}
      >
        <div className="background-image-container">
          <img
            className="background-image"
            src={
              getProfileData?.data?.profile_photo?.optimized_photo ||
              getProfileData?.data?.profile_photo?.original_photo
            }
          />
        </div>

        <div className="private-call-suspend-content-container">
          <div className="close-button-container" onClick={onCloseDialog}>
            <CloseIcon className="close-button" />
          </div>

          <div className="padding-container">
            <div className="caution-icon-container">
              <ErrorOutlineRoundedIcon className="caution-icon" />
            </div>

            <div style={{ padding: "2px" }} />

            <div className="private-call-suspended-title">
              {t(
                suspendedObj?.is_permanent
                  ? "1on1.suspended_permanent_title_dialog"
                  : "1on1.suspended_title_dialog"
              )}
            </div>

            <div style={{ padding: "10px" }} />

            <div className="private-call-suspended-description">
              {t(
                suspendedObj?.is_permanent
                  ? "1on1.suspended_permanent_description_dialog"
                  : "1on1.suspended_description_dialog"
              )}
            </div>

            <div style={{ padding: "10px" }} />

            {suspendedObj?.is_permanent === false && (
              <div className="private-call-suspended-description-2">
                {t("1on1.suspended_description_2_dialog", {
                  minutes: suspendedObj?.suspension_duration_in_minutes,
                })}
              </div>
            )}

            <div style={{ padding: "20px" }} />

            {suspendedObj?.is_permanent === false && (
              <div className="private-call-suspended-countdown">
                {t("1on1.mins_left", {
                  minutes: getSuspensionCopywriting(suspendedSeconds),
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default PrivateCallSuspendDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
