// General
import "./callee-camera-feed-overlay.scss";
import { useState, useEffect, useRef } from "react";
// Servics
import { useUploadPrivateStandbyScreenshotMutation } from "../../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  // Interval Functions
  updateUploadScreenshotInterval,
  clearUploadScreenshotInterval,

  // Permissions Functions
  updateCameraPermissions,
  updateMicrophonePermissions,
  updatePermissionErrorMessage,

  // Devices Functions
  updateAllDevices,
  updateCameraDevices,
  updateMicrophoneDevices,

  // Utility Functions
  updateChangeCameraMicrophonePassthrough,
  updateIsLocalCameraEnabled,
  updateIsLocalMicrophoneEnabled,
  updateUploadScreenshotPassthrough,
  updateUploadScreenshotIntervalPaused,
} from "../../../../../redux/store/privateCallStore";
import { updateVideoCallErrorDialog } from "../../../../../redux/store/dialogStore";
import { updateWarningToast } from "../../../../../redux/store/toastStore";
// Tencent Real Time Communication (TRTC)
import TRTC from "trtc-js-sdk";
// react-device-detect
import { isFirefox, isSafari } from "react-device-detect";
// browser-image-compression
import imageCompression from "browser-image-compression";
// Material UI
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useIsMounted from "../../../../utility/custom-hooks/useIsMounted-hook";

const CalleeCameraFeed = (props) => {
  const { trtcClient } = props;

  // API variables
  const [
    uploadPrivateStandbyScreenshot,
    {
      data: uploadPrivateStandbyScreenshotData,
      error: uploadPrivateStandbyScreenshotError,
      isLoading: uploadPrivateStandbyScreenshotLoading,
      isSuccess: uploadPrivateStandbyScreenshotSuccess,
      isError: uploadPrivateStandbyScreenshotFailure,
    },
  ] = useUploadPrivateStandbyScreenshotMutation();

  // General variables
  const [localStream, setLocalStream] = useState(null);
  const [localStreamInitialized, setLocalStreamInitialized] = useState(false);
  const [localStreamReinitialized, setLocalStreamReinitialized] =
    useState(false); // Somehow during autopilot, wrote this variable and it works
  const isMounted = useRef(false);

  // Redux variables
  const calleeId = useSelector((state) => state.privateCall.calleeId);
  const roomId = useSelector((state) => state.privateCall.roomId);
  const callStatus = useSelector((state) => state.privateCall.callStatus);
  const selectedCameraDevice = useSelector(
    (state) => state.privateCall.selectedCameraDevice
  );
  const selectedMicrophoneDevice = useSelector(
    (state) => state.privateCall.selectedMicrophoneDevice
  );
  const reinitializeCameraStreamPassthrough = useSelector(
    (state) => state.privateCall.reinitializeCameraStreamPassthrough
  );
  const changeCameraMicrophonePassthrough = useSelector(
    (state) => state.privateCall.changeCameraMicrophonePassthrough
  );
  const swapVideoViewPassthrough = useSelector(
    (state) => state.privateCall.swapVideoViewPassthrough
  );
  const toggleCameraPassthrough = useSelector(
    (state) => state.privateCall.toggleCameraPassthrough
  );
  const toggleRemoteCameraPassthrough = useSelector(
    (state) => state.privateCall.toggleRemoteCameraPassthrough
  );
  const isLocalCameraEnabled = useSelector(
    (state) => state.privateCall.isLocalCameraEnabled
  );
  const toggleMicrophonePassthrough = useSelector(
    (state) => state.privateCall.toggleMicrophonePassthrough
  );
  const uploadScreenshotPassthrough = useSelector(
    (state) => state.privateCall.uploadScreenshotPassthrough
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const mounted = useIsMounted();

  // Lifecycle | Mounted
  useEffect(() => {
    // Set localStream to null at the start is to prevent clear any previous localStream
    setLocalStream(null);
    setLocalStreamInitialized(false);

    initTRTCDevices();

    if (!isFirefox && !isSafari) {
      // Firefox does not support permissions API (camera & microphone)
      // Safari does not support on listen event for camera & microphone
      if (onCheckMediaDevicesAPIExists()) {
        // Mainly to get on change events for camera & microphone permissions
        onCheckCameraPermissions();
        onCheckMicrphonePermissions();
      }
    }
  }, []);

  // Lifecycle | Unmounted
  useEffect(() => {
    return () => {
      if (!mounted()) {
        if (localStream) {
          dispatch(clearUploadScreenshotInterval());
          dispatch(updateUploadScreenshotIntervalPaused(null));
          localStream.close();
          setLocalStream(null);
        }
      }
    };
  }, [mounted]);

  // Lifecycle | Check for update | trtcClient
  useEffect(() => {
    if (
      !trtcClient ||
      !calleeId ||
      !roomId ||
      !localStreamInitialized ||
      localStreamReinitialized
    )
      return;

    trtcClient
      .join({
        roomId: roomId,
      })
      .then(() => {
        trtcClient.publish(localStream);
      });
  }, [trtcClient, calleeId, roomId, localStreamInitialized]);

  // Lifecycle | Check for update | localStream
  useEffect(() => {
    if (!localStream) return;

    localStream
      .initialize()
      .then(() => {
        // Initialize camera and microphone info to use for camera and microphone selection
        initTRTCDevices();

        // Reason we use localStreamInitialized to determine if we should publish the localStream is because we want to make sure that the localStream is initialized before we publish it
        // Using localStream will not work sometimes because localStream might be created but not initialized, thus TRTC throwing error
        setLocalStreamInitialized(true);
        localStream?.play("callee-video");
        dispatch(updateIsLocalCameraEnabled(true));

        dispatch(
          updateUploadScreenshotInterval(
            setInterval(() => {
              dispatch(updateUploadScreenshotPassthrough({}));
            }, 30000)
          )
        );

        if (!localStreamReinitialized) return;

        trtcClient.publish(localStream);
      })
      .catch((err) => {
        console.log("TRTC createLocalStream err:", err);

        // Open Toast
        let toastObj;

        // Open Dialog
        if (err.toString().includes("user denied permission")) {
          dispatch(
            updatePermissionErrorMessage(
              "Unable to access camera or microphone"
            )
          );
          toastObj = {
            message: t("1on1.error_permissions_2"),
            autoClose: 3000,
          };
        } else if (err.toString().includes("no camera detected")) {
          dispatch(updatePermissionErrorMessage("No camera detected"));
          toastObj = {
            message: t("1on1.error_permissions_2"),
            autoClose: 3000,
          };
        } else if (
          err.toString().includes("NotAllowedError: Permission denied")
        ) {
          dispatch(
            updatePermissionErrorMessage(
              "Unable to access camera or microphone"
            )
          );
          toastObj = {
            message: t("1on1.error_permissions_2"),
            autoClose: 3000,
          };
        } else {
          // TODO: Find more errors
          toastObj = {
            message: err?.toString(),
            autoClose: 3000,
          };
        }

        dispatch(updateWarningToast(toastObj));
        dispatch(updateVideoCallErrorDialog(true));
      });
  }, [localStream, localStreamReinitialized]);

  // Lifecycle | Check for update | calleeId
  useEffect(() => {
    if (!calleeId || localStream) return;

    createLocalStream();
  }, [calleeId, localStream]);

  // Lifecycle | Check for update | reinitializeCameraStreamPassthrough
  useEffect(() => {
    if (isMounted.current) {
      if (!reinitializeCameraStreamPassthrough || !localStream || !trtcClient)
        return;

      // Unpublish Local Stream
      trtcClient.unpublish(localStream).then(() => {
        setLocalStreamReinitialized(true);

        // Reset Local Stream
        setLocalStreamInitialized(false);
        localStream.close();
        setLocalStream(null);
      });
    } else {
      isMounted.current = true;
    }
  }, [reinitializeCameraStreamPassthrough]);

  // Lifecycle | Check for update | changeCameraMicrophonePassthrough
  useEffect(() => {
    if (!changeCameraMicrophonePassthrough) return;

    // Switch video source
    localStream?.switchDevice("video", selectedCameraDevice || "user");

    // Switch audio source
    // localStream.switchDevice("audio", selectedMicrophoneDevice);
  }, [changeCameraMicrophonePassthrough]);

  // Lifecycle | Check for update | swapVideoViewPassthrough
  useEffect(() => {
    if (!swapVideoViewPassthrough) return;

    switch (swapVideoViewPassthrough) {
      case "mini":
        localStream?.play("callee-video");
        break;
      case "full":
        localStream?.play("caller-video");
        break;
      default:
        localStream?.play("callee-video");
        break;
    }
  }, [swapVideoViewPassthrough]);

  // Lifecycle | Check for update | toggleCameraPassthrough
  useEffect(() => {
    if (toggleCameraPassthrough) {
      if (localStream) {
        const hasVideoTrack = localStream?.getVideoTrack();

        if (hasVideoTrack) {
          if (hasVideoTrack?.enabled) {
            // const muteVideo = localStream.muteVideo();
            dispatch(updateIsLocalCameraEnabled(false));
          } else {
            // const unmuteVideo = localStream.unmuteVideo();
            dispatch(updateIsLocalCameraEnabled(true));
          }
        } else {
          // If there is no video track, we need to reinitialize the stream
          trtcClient.unpublish(localStream).then(() => {
            setLocalStreamReinitialized(true);

            // Reset Local Stream
            setLocalStreamInitialized(false);
            localStream.close();
            setLocalStream(null);

            // Get Camera Access
            createLocalStream();
          });
        }
      }
    }
  }, [toggleCameraPassthrough]);

  // Lifecycle | Check for update | toggleMicrophonePassthrough
  useEffect(() => {
    if (toggleMicrophonePassthrough) {
      const hasAudioTrack = localStream.getAudioTrack();

      if (hasAudioTrack) {
        if (hasAudioTrack?.enabled) {
          const muteAudio = localStream.muteAudio(); // Do not remove
          dispatch(updateIsLocalMicrophoneEnabled(false));
        } else {
          const unmuteAudio = localStream.unmuteAudio(); // Do not remove
          dispatch(updateIsLocalMicrophoneEnabled(true));
        }
      }
    }
  }, [toggleMicrophonePassthrough]);

  // Lifecycle | Check for update | uploadScreenshotPassthrough
  useEffect(() => {
    if (uploadScreenshotPassthrough) {
      getVideoFrame();
    }
  }, [uploadScreenshotPassthrough]);

  // TRTC Functions
  const initTRTCDevices = () => {
    TRTC.Logger.setLogLevel(TRTC.Logger.LogLevel.ERROR);

    TRTC.getDevices()
      .then((devices) => {
        const devicesObj = devices?.map((device) => {
          return {
            deviceId: device?.deviceId,
            groudId: device?.groupId,
            kind: device?.kind,
            label: device?.label,
          };
        });

        dispatch(updateAllDevices(devicesObj));
      })
      .catch((err) => {
        console.log("TRTC getDevices err:", err);
      });

    TRTC.getCameras()
      .then((cameras) => {
        let cameraObj = cameras?.map((camera) => {
          return {
            deviceId: camera?.deviceId,
            groudId: camera?.groupId,
            kind: camera?.kind,
            label: camera?.label,
          };
        });

        dispatch(updateCameraDevices(cameraObj));
      })
      .catch((err) => {
        console.log("TRTC getCameras err:", err);
      });

    TRTC.getMicrophones()
      .then((microphones) => {
        let microphoneObj = microphones?.map((microphone) => {
          return {
            deviceId: microphone?.deviceId,
            groudId: microphone?.groupId,
            kind: microphone?.kind,
            label: microphone?.label,
          };
        });

        dispatch(updateMicrophoneDevices(microphoneObj));
      })
      .catch((err) => {
        console.log("TRTC getMicrophones err:", err);
      });
  };

  // TRTC Functions | TRTC Method to get Camera & Microphone Access
  const createLocalStream = () => {
    setLocalStream(
      TRTC.createStream({
        audio: true,
        video: true,
        userId: calleeId,
      })
    );
  };

  // TRTC Functions | Capture Video Frame | Testing
  const getVideoFrame = () => {
    const frame = localStream.getVideoFrame();
    compressImage(frame);
  };

  // Utility Functions
  const onCheckMediaDevicesAPIExists = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      return true;
    } else {
      return false;
    }
  };
  const onCheckCameraPermissions = () => {
    navigator.permissions.query({ name: "camera" }).then((result) => {
      if (result.state === "prompt") {
        dispatch(updateCameraPermissions(false));
      } else if (result.state === "granted") {
        dispatch(updateCameraPermissions(true));
      } else if (result.state === "denied") {
        dispatch(updateCameraPermissions(false));
        console.log("User need to manually enable camera permissions");
      }

      // Check for changes in camera permissions
      result.onchange = function () {
        if (result.state === "prompt") {
          dispatch(updateCameraPermissions(false));
        } else if (result.state === "granted") {
          dispatch(updateCameraPermissions(true));
        } else if (result.state === "denied") {
          dispatch(updateCameraPermissions(false));
          console.log("User need to manually enable camera permissions");
        }
      };
    });
  };
  const onCheckMicrphonePermissions = () => {
    navigator.permissions.query({ name: "microphone" }).then((result) => {
      if (result.state === "prompt") {
        dispatch(updateMicrophonePermissions(false));
      } else if (result.state === "granted") {
        dispatch(updateMicrophonePermissions(true));
      } else if (result.state === "denied") {
        dispatch(updateMicrophonePermissions(false));
      }

      // Check for changes in microphone permissions
      result.onchange = function () {
        if (result.state === "prompt") {
          dispatch(updateMicrophonePermissions(false));
        } else if (result.state === "granted") {
          dispatch(updateMicrophonePermissions(true));
        } else if (result.state === "denied") {
          dispatch(updateMicrophonePermissions(false));
        }
      };
    });
  };
  const compressImage = async (base64) => {
    const file = base64ToFile(base64, "screenshot.jpg");

    const options = {
      maxSizeMB: 0.01,
      maxWidthOrHeight: 600,
    };

    try {
      const compressedFile = await imageCompression(file, options);
      const imageData = await readFile(compressedFile);

      if (imageData) {
        const obj = {
          image_base64: imageData,
        };
        uploadPrivateStandbyScreenshot(obj);
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  };
  const base64ToFile = (base64, filename) => {
    const arr = base64.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };
  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  // Camera & Microphone Functions | Native JS Method
  const onGetCameraAccess = () => {
    const video = document.getElementById("callee-video");

    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: { facingMode: "user" } })
        .then((stream) => {
          video.srcObject = stream;

          var playPromise = video.play();

          if (playPromise !== undefined) {
            playPromise
              .then((_) => {
                // Automatic playback started!
                // Show playing UI.
              })
              .catch((error) => {
                // Auto-play was prevented
                // Show paused UI.
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const onGetMicrophoneAccess = () => {
    const video = document.getElementById("callee-video");

    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          video.srcObject = stream;

          var playPromise = video.play();

          if (playPromise !== undefined) {
            playPromise
              .then((_) => {
                // Automatic playback started!
                // Show playing UI.
              })
              .catch((error) => {
                // Auto-play was prevented
                // Show paused UI.
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div id="private-standby-callee-camera-feed-overlay-subcomponent">
      <div className="padding-container">
        <div id="callee-video"></div>
        {((callStatus === "JOIN" &&
          swapVideoViewPassthrough === "full" &&
          !toggleRemoteCameraPassthrough) ||
          ((!swapVideoViewPassthrough || swapVideoViewPassthrough === "mini") &&
            !isLocalCameraEnabled)) && (
          <div className="camera-disabled-container">
            <VideocamOffIcon className="camera-disabled-icon" />
          </div>
        )}
      </div>
    </div>
  );
};

export default CalleeCameraFeed;
