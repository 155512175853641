// General
import "./category-tab.scss";
import { useEffect } from "react";
// Services
import {
  useLazyGetProfileQuery,
  useLazyGetUserAioQuery,
} from "../../../../services/data.service";
// Static Data
import searchConst from "../../../../const/searchConst";
import utilityConst from "../../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  resetDatingSearchLists,
  updateSelectedCategoryTab,
  updateSelectedView,
} from "../../../../redux/store/searchStore";
import { updateSearchFilterDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Sentry
import * as Sentry from "@sentry/react";
// Material UI
import { Tabs, Tab, Box, Skeleton, useMediaQuery } from "@mui/material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GridViewIcon from "@mui/icons-material/GridView";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import PropTypes from "prop-types";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const CategoryTab = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();

  // Constant variables
  let categoryTab = searchConst.categoryTab;

  // Redux variables
  const selectedCategoryTab = useSelector(
    (state) => state.search.selectedCategoryTab
  );
  const selectedView = useSelector((state) => state.search.selectedView);
  const isFiltered = useSelector((state) => state.searchFilter.isFiltered);
  const releaseChannel = useSelector((state) => state.app.releaseChannel);
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
    getUserAio(null, false);
  }, []);

  // Lifecycle | Check for update | isFiltered
  useEffect(() => {
    if (isFiltered) {
      dispatch(updateSelectedCategoryTab("filtered"));
    } else {
      if (selectedCategoryTab === "filtered") {
        dispatch(updateSelectedCategoryTab("recently_active"));
      }
    }
  }, [isFiltered]);

  // Lifecycle | Check for update | Get Profile API Response
  useEffect(() => {
    if (getProfileError) {
      // Send to Sentry
      Sentry.captureMessage(
        "Category Tab /me Error: " + JSON.stringify(getProfileErrorData)
      );
    }
  }, [getProfileError]);

  // Event Handlers | Button
  const onSelectedTab = (item) => {
    if (selectedCategoryTab !== item) {
      TagManager.dataLayer({
        dataLayer: {
          event: `PWA-7-7.1-${item}-Button`,
        },
      });

      dispatch(updateSelectedCategoryTab(item));
      dispatch(resetDatingSearchLists());
    }
  };
  const onToggleView = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-7-7.1-ToggleView-Button",
      },
    });

    dispatch(
      updateSelectedView(
        selectedView === searchConst.userCardDisplay.list
          ? searchConst.userCardDisplay.grid
          : searchConst.userCardDisplay.list
      )
    );
  };
  const onRefreshCategoryTab = () => {
    getProfile(null, false);
  };
  const onOpenSearchFilterDialog = () => {
    dispatch(updateSearchFilterDialog(true));
  };

  // Event Handlers | MUI Tabs
  const onTabChange = (event, newValue) => {
    const searchTabs = getUserAioData?.data?.pwa?.search_tabs;
    const recentlyActiveTab = searchTabs?.recently_active;
    const lgbtqRecentlyActiveTab = searchTabs?.lgbtq_recently_active;
    const nearestTab = searchTabs?.nearest;
    const newestTab = searchTabs?.newest;
    const premiumTab = searchTabs?.premium;
    const collegeTab = searchTabs?.college;
    const masqueradePartyTab = searchTabs?.masquerade_party;

    categoryTab = categoryTab.filter((item) => {
      if (item.value === "recently-active") {
        return recentlyActiveTab;
      } else if (item.value === "nearest") {
        return nearestTab;
      } else if (item.value === "newest") {
        return newestTab;
      } else if (item.value === "premium") {
        return premiumTab;
      } else if (item.value === "lgbtq-recently-active") {
        return lgbtqRecentlyActiveTab;
      } else if (item.value === "college") {
        return collegeTab;
      } else if (item.value === "masquerade-party") {
        return masqueradePartyTab;
      } else if (item.value === "filtered") {
        return true;
      } else {
        return false;
      }
    });

    let tabValue = categoryTab[isFiltered ? newValue : newValue + 1]?.toApi;
    dispatch(updateSelectedCategoryTab(tabValue));

    TagManager.dataLayer({
      dataLayer: {
        event: `PWA-7-7.5-${tabValue}-Button`,
      },
    });
  };

  // Utility Functions
  const getTabValue = () => {
    const searchTabs = getUserAioData?.data?.pwa?.search_tabs;
    const recentlyActiveTab = searchTabs?.recently_active;
    const lgbtqRecentlyActiveTab = searchTabs?.lgbtq_recently_active;
    const nearestTab = searchTabs?.nearest;
    const newestTab = searchTabs?.newest;
    const premiumTab = searchTabs?.premium;
    const collegeTab = searchTabs?.college;
    const masqueradePartyTab = searchTabs?.masquerade_party;

    const categoryTabTemp = categoryTab.filter((item) => {
      if (item.value === "recently-active") {
        return recentlyActiveTab;
      } else if (item.value === "nearest") {
        return nearestTab;
      } else if (item.value === "newest") {
        return newestTab;
      } else if (item.value === "premium") {
        return premiumTab;
      } else if (item.value === "lgbtq-recently-active") {
        return lgbtqRecentlyActiveTab;
      } else if (item.value === "college") {
        return collegeTab;
      } else if (item.value === "masquerade-party") {
        return masqueradePartyTab;
      } else if (item.value === "filtered") {
        return true;
      } else {
        return false;
      }
    });

    let tabValue = categoryTabTemp.findIndex(
      (item) => item.toApi === selectedCategoryTab
    );
    return isFiltered ? tabValue : tabValue - 1;
  };

  if (getProfileData || (getProfileSuccess && getUserAioSuccess)) {
    if (getProfileData?.status === 0 && getUserAioData?.status === 1) {
      const searchTabs = getUserAioData?.data?.pwa?.search_tabs;
      const recentlyActiveTab = searchTabs?.recently_active;
      const lgbtqRecentlyActiveTab = searchTabs?.lgbtq_recently_active;
      const nearestTab = searchTabs?.nearest;
      const newestTab = searchTabs?.newest;
      const premiumTab = searchTabs?.premium;
      const collegeTab = searchTabs?.college;
      const masqueradePartyTab = searchTabs?.masquerade_party;

      // filter out the tabs that are not enabled
      categoryTab = categoryTab.filter((item) => {
        if (item.value === "recently-active") {
          return recentlyActiveTab;
        } else if (item.value === "nearest") {
          return nearestTab;
        } else if (item.value === "newest") {
          return newestTab;
        } else if (item.value === "premium") {
          return premiumTab;
        } else if (item.value === "lgbtq-recently-active") {
          return lgbtqRecentlyActiveTab;
        } else if (item.value === "college") {
          return collegeTab;
        } else if (item.value === "masquerade-party") {
          return masqueradePartyTab;
        } else if (item.value === "filtered") {
          return true;
        } else {
          return false;
        }
      });
    }

    return (
      <div
        id="search-category-tab-subcomponent"
        className={!isMobile ? "category-tab-full-width" : "apply-padding"}
      >
        {isMobile && (
          <div className="category-container">
            {categoryTab?.map((item, index) => {
              if (!isFiltered && item?.value === "filtered") {
                return;
              }

              return (
                <div
                  className={`tab-container ${
                    selectedCategoryTab === item?.toApi ? "selected-tab" : ""
                  }`}
                  key={index}
                  onClick={() => onSelectedTab(item?.toApi)}
                >
                  {item?.value === "1-on-1-video" && (
                    <div className="new-badge-container">
                      {getIcon("newBadge", "new-badge")}
                    </div>
                  )}

                  {item?.value === "masquerade-party" && (
                    <div className="masquerade-mask-icon-container">
                      {getIcon("masqueradeMaskIcon", "masquerade-mask-icon")}
                    </div>
                  )}

                  {t(`${item?.jsonTitle}`)}
                </div>
              );
            })}
          </div>
        )}

        {!isMobile && (
          <div
            className={`category-form-field-container ${
              !isMobile ? "category-tab-full-width" : ""
            }`}
          >
            <Tabs
              className="custom-tabs add-underline"
              value={getTabValue()}
              onChange={onTabChange}
              TabIndicatorProps={{ style: { backgroundColor: "#710d0d" } }}
              variant="fullWidth"
            >
              {categoryTab?.map((item, index) => {
                if (!isFiltered && item?.value === "filtered") {
                  return;
                }

                return (
                  <Tab
                    label={t(`${item?.jsonTitle}`)}
                    icon={
                      item?.value === "masquerade-party" ? (
                        <div className="masquerade-mask-icon-container">
                          {getIcon(
                            "masqueradeMaskIcon",
                            "masquerade-mask-icon"
                          )}
                        </div>
                      ) : null
                    }
                    iconPosition="start"
                    key={index}
                    {...a11yProps(index)}
                  />
                );
              })}
            </Tabs>
          </div>
        )}

        {isMobile && (
          <div
            className="filter-icon-container"
            onClick={onOpenSearchFilterDialog}
          >
            {isFiltered
              ? getIcon("searchFilteredIcon", "filter-icon")
              : getIcon("searchFilterIcon", "filter-icon")}
            {/* <div className="filter-label">{t("filter.filters")}</div> */}
          </div>
        )}

        {releaseChannel !== utilityConst.releaseChannel.stable && (
          <div className="view-select-container">
            {(!isMobile ||
              selectedView === searchConst.userCardDisplay.grid) && (
              <FormatListBulletedIcon
                className={`list-view-button ${
                  !isMobile && selectedView !== searchConst.userCardDisplay.list
                    ? "deselected-view"
                    : ""
                }`}
                onClick={onToggleView}
              />
            )}
            {(!isMobile ||
              selectedView === searchConst.userCardDisplay.list) && (
              <GridViewIcon
                className={`grid-view-button ${
                  !isMobile && selectedView !== searchConst.userCardDisplay.grid
                    ? "deselected-view"
                    : ""
                }`}
                onClick={onToggleView}
              />
            )}
          </div>
        )}
      </div>
    );
  } else if (
    getProfileFetching ||
    getProfileLoading ||
    getUserAioFetching ||
    getUserAioLoading
  ) {
    return (
      <div
        id="search-category-tab-skeleton-subcomponent"
        className="apply-padding"
      >
        {isMobile && (
          <div className="category-container">
            <Skeleton className="skeleton-tab" variant="text" />
            <Skeleton className="skeleton-tab" variant="text" />
            <Skeleton className="skeleton-tab" variant="text" />
            <Skeleton className="skeleton-tab" variant="text" />
          </div>
        )}

        {!isMobile && (
          <div
            className={`category-form-field-container ${
              !isMobile ? "category-tab-full-width" : ""
            }`}
          >
            <Skeleton className="skeleton-tab" variant="text" />
            <Skeleton className="skeleton-tab" variant="text" />
            <Skeleton className="skeleton-tab" variant="text" />
            <Skeleton className="skeleton-tab" variant="text" />
          </div>
        )}
      </div>
    );
  }
  // else if (getProfileSuccess && getUserAioSuccess) {
  //   if (getProfileData?.status === 0 && getUserAioData?.status === 1) {
  //     const searchTabs = getUserAioData?.data?.pwa?.search_tabs;
  //     const recentlyActiveTab = searchTabs?.recently_active;
  //     const nearestTab = searchTabs?.nearest;
  //     const newestTab = searchTabs?.newest;
  //     const premiumTab = searchTabs?.premium;
  //     const collegeTab = searchTabs?.college;
  //     const masqueradePartyTab = searchTabs?.masquerade_party;

  //     // filter out the tabs that are not enabled
  //     categoryTab = categoryTab.filter((item) => {
  //       if (item.value === "recently-active") {
  //         return recentlyActiveTab;
  //       } else if (item.value === "nearest") {
  //         return nearestTab;
  //       } else if (item.value === "newest") {
  //         return newestTab;
  //       } else if (item.value === "premium") {
  //         return premiumTab;
  //       } else if (item.value === "college") {
  //         return collegeTab;
  //       } else if (item.value === "masquerade-party") {
  //         return masqueradePartyTab;
  //       } else {
  //         return true;
  //       }
  //     });
  //   }

  //   return (
  //     <div
  //       id="search-category-tab-subcomponent"
  //       className={!isMobile ? "category-tab-full-width" : "apply-padding"}
  //     >
  //       {isMobile && (
  //         <div className="category-container">
  //           {categoryTab?.map((item, index) => {
  //             if (!isFiltered && item?.value === "filtered") {
  //               return;
  //             }

  //             return (
  //               <div
  //                 className={`tab-container ${
  //                   selectedCategoryTab === item?.toApi ? "selected-tab" : ""
  //                 }`}
  //                 key={index}
  //                 onClick={() => onSelectedTab(item?.toApi)}
  //               >
  //                 {item?.value === "1-on-1-video" && (
  //                   <div className="new-badge-container">
  //                     {getIcon("newBadge", "new-badge")}
  //                   </div>
  //                 )}
  //                 {t(`${item?.jsonTitle}`)}
  //               </div>
  //             );
  //           })}
  //         </div>
  //       )}

  //       {!isMobile && (
  //         <div
  //           className={`category-form-field-container ${
  //             !isMobile ? "category-tab-full-width" : ""
  //           }`}
  //         >
  //           <Tabs
  //             className="custom-tabs add-underline"
  //             value={getTabValue()}
  //             onChange={onTabChange}
  //             TabIndicatorProps={{ style: { backgroundColor: "#710d0d" } }}
  //             variant="fullWidth"
  //           >
  //             {categoryTab?.map((item, index) => {
  //               if (!isFiltered && item?.value === "filtered") {
  //                 return;
  //               }

  //               return (
  //                 <Tab label={t(`${item?.jsonTitle}`)} {...a11yProps(index)} />
  //               );
  //             })}
  //           </Tabs>
  //         </div>
  //       )}

  //       {releaseChannel !== utilityConst.releaseChannel.stable && (
  //         <div className="view-select-container">
  //           {(!isMobile ||
  //             selectedView === searchConst.userCardDisplay.grid) && (
  //             <FormatListBulletedIcon
  //               className={`list-view-button ${
  //                 !isMobile && selectedView !== searchConst.userCardDisplay.list
  //                   ? "deselected-view"
  //                   : ""
  //               }`}
  //               onClick={onToggleView}
  //             />
  //           )}
  //           {(!isMobile ||
  //             selectedView === searchConst.userCardDisplay.list) && (
  //             <GridViewIcon
  //               className={`grid-view-button ${
  //                 !isMobile && selectedView !== searchConst.userCardDisplay.grid
  //                   ? "deselected-view"
  //                   : ""
  //               }`}
  //               onClick={onToggleView}
  //             />
  //           )}
  //         </div>
  //       )}
  //     </div>
  //   );
  // }
  else if (getProfileError || getUserAioError) {
    return (
      <div id="search-category-error-container" onClick={onRefreshCategoryTab}>
        <RefreshRoundedIcon className="refresh-icon" />
        {t("common.something_went_wrong")}
      </div>
    );
  }
};

export default CategoryTab;

// Material UI | Tab Functions
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      // hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }} className="tab-box-container">
          {children}
          {/* <Typography component={"div"}>{children}</Typography> */}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
