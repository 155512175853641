const pusherConst = {
  globalPusher: {
    channel: {
      privateChannelSubscribe: "private-channel-",
      privateChannelBind: "private-message-event",
    },
    payloadType: {
      notification: "notification",
      notificationSubType: {
        viewProfile: "view_profile",
        favoriteUser: "favorite_user",
        privatePhotoRequest: "private_photo_request",
        privatePhotoApprove: "private_photo_approve",
        live: "live",
        textMessage: "text_message",
      },
      privateMessages: "private-messages",
      callRequest: "call_request",
      joinWaitingRoom: "join_waiting_room",
      cancelCall: "cancel_call",
      startCall: "start_call",
      missedCall: "missed_call",
      rejectCall: "reject_call",
      endPrivateCallStandby: "end_private_call_standby",
    },
  },
  liveViewPusher: {
    channel: {
      privateStreamSubscribe: "private-stream-",
      privateStreamClientChatBind: "client-chat",
      privateStreamChatBind: "chat",
      generalStreamSubscribe: "general-stream-",
      generalStreamBind: "general-message",
      generalStreamGlobalSubscribe: "general-stream-global",
      generalStreamGlobalBind: "general-message",
    },
    payloadType: {
      emoji: "emoji",
      emojiAnimation: "emoji_animation",
      joining: "joining",
      message: "message",
      messageV2: "message.v2",
      gifting: "gifting",
      following: "following",
      streamPaused: "stream_paused",
      streamResume: "stream_resume",
      streamEnded: "stream_ended",
      viewerKicked: "viewer_kicked",
      kicked: "kicked",
      refresh: "refresh",
      coAnchorStart: "co_anchor_start",
      coAnchorEnd: "co_anchor_end",
      coAnchorPkInvite: "co_anchor_pk_invite",
      coAnchorPkAccpeted: "co_anchor_pk_accepted",
      coAnchorPkStarted: "co_anchor_pk_started",
      coAnchorPkStart: "co_anchor_pk_start",
      coAnchorPkRoundStart: "co_anchor_pk_round_start",
      coAnchorPkRoundEnd: "co_anchor_pk_round_end",
      coAnchorPkEnd: "co_anchor_pk_end",
      pk: "pk",
    },
  },
  videoCallPusher: {
    channel: {
      privateCallSubscribe: "private-call-",
      privateCallBind: "private-calls-event",
      privateCallClientBind: "client-private-calls-event",
    },
    payloadType: {
      callMinutesEarned: "call_minutes_earned",
      emoji: "emoji",
      message: "message",
      giftRequest: "gift_request",
      coinRequest: "coin_request",
      gifting: "gifting",
      tippingSent: "tipping_sent",
      tippingCoinsSent: "tipping_coins_sent",
      tippingRequestSent: "tipping_request_sent",
      tippingRequestAccepted: "tipping_request_accepted",
      tippingRequestRejected: "tipping_request_rejected",
      menuChanged: "menu_changed",
      endCall: "end_call",
      lowBalance: "low_balance",
      kickUser: "kick_user",
      refresh: "refresh",
      emojiAnimation: "emoji_animation",
    },
  },
  messagingPusher: {
    channel: {
      privateChannelSubscribe: "private-channel-",
      privateChannelBind: "private-message-event",
    },
    payloadType: {
      privateMessages: "private-messages",
    },
  },
};

export default pusherConst;
