// General
import { createBrowserRouter } from "react-router-dom";
// Static Data
import routeConst from "./const/routeConst";
import verificationConst from "./const/verificationConst";
import utilityConst from "./const/utilityConst";
// Components
import App from "./App";
import Terms from "./components/terms/terms";
import PrivacyPolicy from "./components/privacy-policy/privacy-policy";
// Login
import Login from "./components/login/login";
// Forgot Password
import ForgotPassword from "./components/forgot-password/forgot-password";
// Sign Up
import Join from "./components/join/join";
import IntroductionSignup from "./components/signup/introduction-signup/introduction-signup";
import PhoneAuth from "./components/signup/phone-auth/phone-auth";
import PhoneAuthentication from "./components/signup/phone-authentication/phone-authentication";
import VerifyPhone from "./components/signup/verify-phone/verify-phone";
import AccountSignup from "./components/signup/account-signup/account-signup";
import UsernameSignup from "./components/signup/username-signup/username-signup";
import UsernameSignupComplete from "./components/signup/username-signup-complete/username-signup-complete";
import InfoSignup from "./components/signup/info-signup/info-signup";
import TorSignup from "./components/signup/tor-signup/tor-signup";
import DescriptionSignup from "./components/signup/description-signup/description-signup";
import LifestyleSignup from "./components/signup/lifestyle-signup/lifestyle-signup";
import DetailSignup from "./components/signup/detail-signup/detail-signup";
import DetailsSignup from "./components/signup/details-signup/details-signup";
import PremiumBenefits from "./components/signup/details-signup/premium-benefits/premium-benefits";
import SignupSuccess from "./components/signup/signup-success/signup-success";
// Email Verification
import EmailVerification from "./components/verification/email-verification/email-verification";
// Mobile Verification
import MobileVerification from "./components/verification/mobile-verification/mobile-verification";
// Profile Verification
import ProfileVerification from "./components/verification/profile-verification/profile-verification";
// Sugarbook Verified
import SugarbookVerified from "./components/verification/sugarbook-verified/sugarbook-verified/sugarbook-verified";
import SugarbookVerifiedStatus from "./components/verification/sugarbook-verified/sugarbook-verified-status/sugarbook-verified-status";
// Customer Support Manual Verification Info
import CustomerSupportManualVerificationInfo from "./components/verification/customer-support-manual-verification-info/customer-support-manual-verification-info";
// Upgrade
import Upgrade from "./components/upgrade/upgrade/upgrade";
import UpgradeCheckout from "./components/upgrade/upgrade-checkout/upgrade-checkout";
import UpgradePayment from "./components/upgrade/upgrade-payment/upgrade-payment";
import UpgradeStatus from "./components/upgrade/upgrade-status/upgrade-status";
import UpgradeRedirect from "./components/upgrade/upgrade-redirect/upgrade-redirect";
// Payment
import AddPaymentMethod from "./components/payment/add-payment-method/add-payment-method";
import PaymentRedirect from "./components/payment/payment-redirect/payment-redirect";
import AlphapoPaymentRedirect from "./components/payment/alphapo-payment-redirect/alphapo-payment-redirect";
// Search
import Search from "./components/search/search/search";
// Messaging
import Inbox from "./components/inbox/inbox/inbox";
import ChatConversation from "./components/inbox/chat-conversation/chat-conversation";
// Live
import Live from "./components/live/live/live";
import Livestream from "./components/live/livestream/livestream";
import LivestreamEnded from "./components/live/livestream-ended/livestream-ended";
import Livestreamer from "./components/live/livestreamer/livestreamer";
// Interest
import Interests from "./components/interests/interests";
// Profile
import ProfileOverview from "./components/profile/overview/overview";
import UserProfile from "./components/profile/view/user-profile";
import StreamerCenter from "./components/profile/streamer-center/streamer-center/streamer-center";
import LastStreamSummary from "./components/profile/streamer-center/last-stream-summary/last-stream-summary";
import Follows from "./components/profile/streamer-center/follows/follows";
import ProfileEdit from "./components/profile/edit/edit";
import ProfileWallet from "./components/profile/wallet/wallet";
import LevelingCenter from "./components/profile/leveling/leveling";
import ProfileCompletion from "./components/profile/profile-completion/profile-completion";
// Settings
import Settings from "./components/settings/settings/settings";
import UpdatePassword from "./components/settings/update-password/update-password";
import UpdateEmail from "./components/settings/update-email/update-email/update-email";
import UpdateEmailVerification from "./components/settings/update-email/update-email-verification/update-email-verification";
import BlockedMembers from "./components/settings/blocked-members/blocked-members";
import PhotoPermissions from "./components/settings/photo-permissions/photo-permissions";
import VideoCallSettings from "./components/settings/video-call-settings/video-call-settings/video-call-settings";
import VideoCallSettingsCustomize from "./components/settings/video-call-settings/customize/customize";
import DeactivateAccount from "./components/settings/deactivate-account/deactivate-account";
// Video Call
import VideoCall from "./components/video-call/video-call/video-call";
import PrivateStandby from "./components/video-call/private-standby/private-standby";
import VideoCallRoom from "./components/video-call/video-call-room/video-call-room";
import VideoCallSummary from "./components/video-call/video-call-summary/video-call-summary";
import VideoCallRate from "./components/video-call/video-call-rate/video-call-rate";
import VideoCallRecentTab from "./components/video-call/video-call/video-call-recent-tab/video-call-recent-tab";
// Feed
import Feed from "./components/feed/feed";
// Leaderboard
import Leaderboard from "./components/leaderboard/leaderboard/leaderboard";
import TopLeaderboardList from "./components/leaderboard/top-leaderboard-list/top-leaderboard-list";
// Support
import Support from "./components/support/support";
// Safety Tips
import SafetyTips from "./components/safety-tips/safety-tips";
// Tutorial
import VideoCallTutorial from "./components/tutorials/video-call-tutorial/video-call-tutorial";
// Report
import Report from "./components/report/report";
// Coin Shop
import CoinShop from "./components/coin-shop/coin-shop";
// Games
import CasinoGames from "./components/games/casino-games/casino-games";
// Logout
import Logout from "./components/logout/logout";
// Redirect Handler
import RedirectHandler from "./components/redirect-handler/redirect-handler";
// Error 404 Page
import Error404Page from "./components/404/404";
// Super Secret
import TestPage from "./components/super-secret/test/test";
import LivenessVerification from "./components/verification/liveness-verification/liveness-verification";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      // General
      { path: routeConst.landing.path, element: <Login /> },
      { path: routeConst.terms.path, element: <Terms /> },
      { path: routeConst.privacy.path, element: <PrivacyPolicy /> },

      // Login
      { path: routeConst.login.path, element: <Login /> },

      // Phone Authentication
      {
        path: routeConst.phoneAuthentication.path,
        element: (
          <PhoneAuthentication type={utilityConst.fillingDetails.signup.type} />
        ),
      },
      // Missing Phone Authentication
      {
        path: routeConst.phoneAuthentication.altPath,
        element: (
          <PhoneAuthentication
            type={utilityConst.fillingDetails.missingDetails.type}
          />
        ),
      },

      // Forgot Password
      { path: routeConst.forgotPassword.path, element: <ForgotPassword /> },

      // Sign Up
      { path: routeConst.join.path, element: <Join /> },
      {
        path: routeConst.introductionSignup.path,
        element: <IntroductionSignup />,
      },
      { path: routeConst.phoneAuth.path, element: <PhoneAuth /> },
      { path: routeConst.verifyPhone.path, element: <VerifyPhone /> },
      { path: routeConst.accountSignup.path, element: <AccountSignup /> },
      {
        path: routeConst.infoSignup.path,
        element: <InfoSignup type={utilityConst.fillingDetails.signup.type} />,
      },
      {
        path: routeConst.usernameSignup.path,
        element: <UsernameSignup />,
      },
      {
        path: routeConst.usernameSignupComplete.path,
        element: <UsernameSignupComplete />,
      },
      {
        path: routeConst.torSignup.path,
        element: <TorSignup />,
      },
      {
        path: routeConst.descriptionSignup.path,
        element: <DescriptionSignup />,
      },
      { path: routeConst.lifestyleSignup.path, element: <LifestyleSignup /> },
      { path: routeConst.detailSignup.path, element: <DetailSignup /> },
      { path: routeConst.detailsSignup.path, element: <DetailsSignup /> },
      { path: routeConst.premiumBenefits.path, element: <PremiumBenefits /> },
      {
        path: routeConst.premiumBenefits.upgrade.path,
        element: <Upgrade showUtilityNavbar={true} />,
      },
      {
        path: routeConst.signupSuccess.path,
        element: <SignupSuccess />,
      },
      {
        path: routeConst.missingDetails.path,
        element: (
          <InfoSignup type={utilityConst.fillingDetails.missingDetails.type} />
        ),
      },

      // Email Verification
      {
        path: routeConst.verify.emailVerification.path,
        element: (
          <EmailVerification type={utilityConst.fillingDetails.signup.type} />
        ),
      },
      // Missing Email Verification
      {
        path: routeConst.verify.emailVerification.altPath,
        element: (
          <EmailVerification
            type={utilityConst.fillingDetails.missingDetails.type}
          />
        ),
      },
      // Mobile Verification
      {
        path: routeConst.verify.mobileVerification.path,
        element: (
          <MobileVerification type={utilityConst.fillingDetails.signup.type} />
        ),
      },
      // Missing Mobile Verification
      {
        path: routeConst.verify.mobileVerification.altPath,
        element: (
          <MobileVerification
            type={utilityConst.fillingDetails.missingDetails.type}
          />
        ),
      },
      // Profile Verification
      {
        path: routeConst.verify.profileVerification.path,
        element: <ProfileVerification />,
      },
      // Sugarbook Verified
      {
        path: routeConst.verify.sugarbookVerified.path,
        // element: <SugarbookVerified />,
        element: <LivenessVerification />,
      },
      // Sugarbook Verified Status Success
      {
        path: routeConst.verify.sugarbookVerified.status.approved.path,
        element: (
          <SugarbookVerifiedStatus
            status={utilityConst.sugarbookVerifiedStatus.approved}
          />
        ),
      },
      // Sugarbook Verified Status Rejected
      {
        path: routeConst.verify.sugarbookVerified.status.rejected.path,
        element: (
          <SugarbookVerifiedStatus
            status={utilityConst.sugarbookVerifiedStatus.rejected}
          />
        ),
      },
      // Sugarbook Verified Status Duplicate
      {
        path: routeConst.verify.sugarbookVerified.status.rejected.duplicate,
        element: (
          <SugarbookVerifiedStatus
            status={utilityConst.sugarbookVerifiedStatus.duplicate}
          />
        ),
      },
      // Customer Support Mobile Manual Verification Info
      {
        path: routeConst.verify.customerSupportInfo.mobile.path,
        element: (
          <CustomerSupportManualVerificationInfo
            type={verificationConst.manualVerificationType.mobile}
          />
        ),
      },
      // Customer Support Facial Manual Verification Info
      {
        path: routeConst.verify.customerSupportInfo.facial.path,
        element: (
          <CustomerSupportManualVerificationInfo
            type={verificationConst.manualVerificationType.facial}
          />
        ),
      },

      // Upgrade
      { path: routeConst.upgrade.path, element: <Upgrade /> },
      {
        path: routeConst.upgrade.checkout.path,
        element: <UpgradeCheckout />,
      },
      { path: routeConst.upgrade.payment.path, element: <UpgradePayment /> },
      { path: routeConst.upgrade.status.path, element: <UpgradeStatus /> },
      {
        path: routeConst.upgrade.redirect.path,
        element: <UpgradeRedirect />,
      },

      // Payment
      { path: routeConst.payment.add.path, element: <AddPaymentMethod /> },
      { path: routeConst.payment.redirect.path, element: <PaymentRedirect /> },
      { path: routeConst.payment.alphapoRedirect.path, element: <AlphapoPaymentRedirect /> },

      // Search
      { path: routeConst.search.path, element: <Search /> },

      // Messages
      { path: routeConst.inbox.path, element: <Inbox /> },
      {
        path: routeConst.inbox.messaging.chat.chatId.path,
        element: <ChatConversation />,
      },

      // Live
      { path: routeConst.live.path, element: <Live /> },
      { path: routeConst.live.liveId.path, element: <Livestream /> },
      { path: routeConst.live.ended.path, element: <LivestreamEnded /> },
      { path: routeConst.live.livestreamer.path, element: <Livestreamer /> },

      // Interest
      { path: routeConst.interest.path, element: <Interests /> },

      // Profile
      {
        path: routeConst.profile.overview.path,
        element: <ProfileOverview />,
      },
      { path: routeConst.profile.view.path, element: <UserProfile /> },
      {
        path: routeConst.profile.streamerCenter.path,
        element: <StreamerCenter />,
      },
      {
        path: routeConst.profile.streamerCenter.lastStreamSummary.path,
        element: <LastStreamSummary />,
      },
      {
        path: routeConst.profile.streamerCenter.follows.path,
        element: <Follows />,
      },
      { path: routeConst.profile.edit.path, element: <ProfileEdit /> },
      { path: routeConst.profile.wallet.path, element: <ProfileWallet /> },
      { path: routeConst.profile.leveling.path, element: <LevelingCenter /> },
      {
        path: routeConst.profile.completion.path,
        element: <ProfileCompletion />,
      },

      // Settings
      { path: routeConst.settings.path, element: <Settings /> },
      {
        path: routeConst.settings.updatePassword.path,
        element: <UpdatePassword />,
      },
      {
        path: routeConst.settings.updateEmail.path,
        element: <UpdateEmail />,
      },
      {
        path: routeConst.settings.updateEmail.verification.path,
        element: <UpdateEmailVerification />,
      },
      {
        path: routeConst.settings.blockedMembers.path,
        element: <BlockedMembers />,
      },
      {
        path: routeConst.settings.photoPermission.path,
        element: <PhotoPermissions />,
      },
      {
        path: routeConst.settings.videoCall.path,
        element: <VideoCallSettings />,
      },
      {
        path: routeConst.settings.videoCall.customize.path,
        element: <VideoCallSettingsCustomize />,
      },
      {
        path: routeConst.settings.deactivateAccount.path,
        element: <DeactivateAccount />,
      },

      // Video Call
      { path: routeConst.videoCall.path, element: <VideoCall /> },
      {
        path: routeConst.videoCall.privateStandby.path,
        element: <PrivateStandby />,
      },
      { path: routeConst.videoCall.room.path, element: <VideoCallRoom /> },
      {
        path: routeConst.videoCall.summary.path,
        element: <VideoCallSummary />,
      },
      { path: routeConst.videoCall.rate.path, element: <VideoCallRate /> },
      {
        path: routeConst.videoCall.callHistory.path,
        element: <VideoCallRecentTab />,
      },

      // Feed
      {
        path: routeConst.feed.path,
        element: <Feed />,
      },

      // Leaderboard
      { path: routeConst.leaderboard.path, element: <Leaderboard /> },
      {
        path: routeConst.leaderboard.list.path,
        element: <TopLeaderboardList />,
      },

      // Support
      { path: routeConst.support.path, element: <Support /> },

      // Safety Tips
      { path: routeConst.safetyTips.path, element: <SafetyTips /> },

      // Tutorial
      {
        path: routeConst.tutorials.videoCall.path,
        element: <VideoCallTutorial />,
      },

      // Report
      { path: routeConst.report.path, element: <Report /> },

      // Coin Shop
      { path: routeConst.coinShop.path, element: <CoinShop /> },

      // Games
      {
        path: routeConst.games.casino.path,
        element: (
          <CasinoGames
            showHeader={false}
            headerType={"desktop"}
            isDialog={false}
          />
        ),
      },

      // Logout
      { path: routeConst.logout.path, element: <Logout /> },

      // Redirect Handler
      { path: routeConst.redirectHandler.path, element: <RedirectHandler /> },

      // Error Page
      { path: "*", element: <Error404Page /> },

      // Super Secret
      // { path: routeConst.superSecret.path, element: <SuperSecret /> },
      {
        path: routeConst.superSecret.liveness.path,
        element: <LivenessVerification />,
      },
    ],
    errorElement: <Error404Page />,
  },
]);

export default router;
