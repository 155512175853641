// General
import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
// Services
import { useLazyGetProfileQuery } from "../../../../services/data.service";
import { API_CONFIG_HEADERS } from "../../../../const/apiConst";
import { sessionService } from "../../../../services/session.service";
// Static Data
import pusherConst from "../../../../const/pusherConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateChatListsLastMessage,
  updateConversationObj,
} from "../../../../redux/store/inboxStore";
import {
  // General Functions
  resetPrivateCallCallingTimer,
  updateExitHunterMode,
  updateRequestId,
  updateShowVideoCallJoiningOverlay,
  updateShowVideoCallEndingOverlay,
  updateEndVideoCall,

  // Chat Functions
  clearVideoCallChatMessages,

  // Interval Functions
  clearPrivateCallCallingInterval,

  // TRTC Functions
  updateCallerUserSig,
  updateCalleeUserSig,
  updateRoomId,

  // Rates Functions
  updateCallerCoins,
  updateCalleeDiamonds,
  updateCalleeRatePerMinute,

  // Caller Functions
  updateCallerId,
  updateCallerUsername,
  updateCallerProfilePhoto,

  // Callee Functions
  updateCalleeId,
  updateCalleeUsername,
  updateCalleeProfilePhoto,

  // Suspended Functions
  updateSuspendedObj,

  // Utility Functions
  updateNotifyEndVideoCallPassthrough,
} from "../../../../redux/store/privateCallStore";
import {
  updateNotificationListFromPusher,
  updateShowNotificationBadge,
} from "../../../../redux/store/notificationStore";
import {
  updateIncomingPrivateCallDialog,
  updatePrivateCallAcceptedDialog,
  updatePrivateCallCallingDialog,
  updatePrivateCallDeclineDialog,
  updatePrivateCallSuspendDialog,
  updatePrivateCallWaitingRoomDialog,
} from "../../../../redux/store/dialogStore";
import { updateGlobalPusherSubscribe } from "../../../../redux/store/pusherStore";
import {
  updateMessageToast,
  updateErrorToast,
  updateInAppToast,
} from "../../../../redux/store/toastStore";
import {
  updateGlobalChannelState,
  updateGlobalPusherState,
} from "../../../../redux/store/debugStore";
// Pusher-js
import Pusher from "pusher-js";
// i18next
import { useTranslation } from "react-i18next";

const GlobalPusher = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // General variables
  const subscribeIsMounted = useRef(false);
  const unsubscribeIsMounted = useRef(false);
  const resetIsMounted = useRef(false);
  const destroyIsMounted = useRef(false);

  // Redux variables
  const isLoggedIn = useSelector((state) => state.public.isLoggedIn);
  const globalPusherSubscribe = useSelector(
    (state) => state.pusher.globalPusherSubscribe
  );
  const globalPusherUnsubscribe = useSelector(
    (state) => state.pusher.globalPusherUnsubscribe
  );
  const globalPusherReset = useSelector(
    (state) => state.pusher.globalPusherReset
  );
  const globalPusherDestroy = useSelector(
    (state) => state.pusher.globalPusherDestroy
  );
  const showLog = useSelector((state) => state.debug.showLog);
  const dispatch = useDispatch();

  // Pusher variables
  let authEndpoint = `${process.env["REACT_APP_SPI_API"]}broadcasting/auth`;
  let [pusherInstance, setPusherInstance] = useState(null);
  let [channel, setChannel] = useState(null);

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!isLoggedIn) return;

    getProfile(null, true);
  }, [isLoggedIn]);

  // Lifecycle | Initiate
  useEffect(() => {
    if (subscribeIsMounted.current) {
      if (!globalPusherSubscribe || !getProfileSuccess || pusherInstance)
        return;

      let headers = {
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        Authorization: `${sessionService.getApiToken()}`,
      };

      setPusherInstance(
        new Pusher(process.env["REACT_APP_PUSHER_APP_KEY"], {
          authEndpoint: authEndpoint,
          cluster: "ap1",
          auth: {
            headers: headers,
          },
        })
      );
    } else {
      subscribeIsMounted.current = true;
    }
  }, [globalPusherSubscribe, getProfileSuccess]);

  // Lifecycle | Check for update | Subscribe
  useEffect(() => {
    if (!pusherInstance) return;

    pusherInstance?.connection?.bind("state_change", (state) => {
      dispatch(updateGlobalPusherState(state.current));

      switch (state.current) {
        case "initialized":
          break;
        case "connecting":
          break;
        case "connected":
          break;
        case "disconnected":
          break;
        case "unavailable":
          break;
        case "failed":
          break;
        case "disconnected":
          break;
        default:
          break;
      }
    });

    setChannel(
      pusherInstance.subscribe(
        `${pusherConst.globalPusher.channel.privateChannelSubscribe}${getProfileData?.data?.id}`
      )
    );
  }, [pusherInstance]);

  // Lifecycle | Check for update | Unsubscribe
  useEffect(() => {
    if (unsubscribeIsMounted.current) {
      if (!globalPusherUnsubscribe) return;

      channel.unsubscribe(
        `${pusherConst.globalPusher.channel.privateChannelSubscribe}${getProfileData?.data?.id}`
      );

      dispatch(updateGlobalChannelState(false));
    } else {
      unsubscribeIsMounted.current = true;
    }
  }, [globalPusherUnsubscribe]);

  // Lifecycle | Check for update | Payload
  useEffect(() => {
    if (!channel) return;

    // Event Listener | State
    channel?.bind("pusher:subscription_succeeded", () => {
      // console.log("Subscribed to channel");
      dispatch(updateGlobalChannelState(true));
    });
    channel?.bind("pusher:subscription_error", (error) => {
      // console.log("Error subscribing to channel: ", error);
      dispatch(updateGlobalChannelState(false));
    });

    // Event Listener | Response
    channel.bind(
      pusherConst.globalPusher.channel.privateChannelBind,
      (payload) => {
        if (showLog) {
          console.log(payload);
        }

        if (payload?.message?.type) {
          let pusherNotificationObj = {
            notificationId: payload?.message?.notification_id,
            type: payload?.message?.action,
            userId: payload?.message?.user_id_string,
            userIdInt: payload?.message?.user_id,
            username: payload?.message?.username,
            userPhoto: payload?.message?.user_photo,
            isVerifiedProfile: payload?.message?.is_verified_profile,
            membershipType: payload?.message?.membership_type,
            relatedId: payload?.message?.related_id,
          };

          switch (payload?.message?.type) {
            case pusherConst.globalPusher.payloadType.notification:
              switch (payload?.message?.action) {
                case pusherConst.globalPusher.payloadType.notificationSubType
                  .viewProfile:
                case pusherConst.globalPusher.payloadType.notificationSubType
                  .favoriteUser:
                case pusherConst.globalPusher.payloadType.notificationSubType
                  .privatePhotoRequest:
                case pusherConst.globalPusher.payloadType.notificationSubType
                  .privatePhotoApprove:
                  dispatch(updateInAppToast(payload?.message));
                  dispatch(
                    updateNotificationListFromPusher(pusherNotificationObj)
                  );
                  dispatch(updateShowNotificationBadge());
                  break;
                case pusherConst.globalPusher.payloadType.notificationSubType
                  .live:
                  pusherNotificationObj.channelStatus = "live";
                  dispatch(
                    updateNotificationListFromPusher(pusherNotificationObj)
                  );
                  dispatch(updateShowNotificationBadge());
                  break;
                case pusherConst.globalPusher.payloadType.notificationSubType
                  .textMessage:
                  dispatch(
                    updateNotificationListFromPusher(pusherNotificationObj)
                  );
                  dispatch(updateShowNotificationBadge());
                  break;
                default:
                  break;
              }
              break;
            case pusherConst.globalPusher.payloadType.privateMessages:
              if (showLog) {
                console.log("G Pusher: Private Messages");
              }

              if (payload?.message?.conversation_id) {
                dispatch(updateConversationObj(payload?.message));

                // Update the chat list and unread list to show live messages
                dispatch(updateChatListsLastMessage(payload?.message));

                // Show toast notification
                dispatch(updateMessageToast(payload?.message));
              }

              break;
            case pusherConst.globalPusher.payloadType.callRequest:
              if (showLog) {
                console.log("G Pusher: Call Request");
              }

              dispatch(
                updateRequestId(
                  payload?.message?.data?.call_request?.call_request_id
                )
              );
              dispatch(
                updateCallerUsername(
                  payload?.message?.data?.call_request?.caller?.username
                )
              );
              dispatch(
                updateCallerProfilePhoto(
                  payload?.message?.data?.call_request?.caller?.profile_photo
                    ?.original_photo
                )
              );
              dispatch(
                updateCalleeUsername(
                  payload?.message?.data?.call_request?.callee?.username
                )
              );
              dispatch(
                updateCalleeProfilePhoto(
                  payload?.message?.data?.call_request?.callee?.profile_photo
                    ?.original_photo
                )
              );
              dispatch(
                updateCallerCoins(
                  payload?.message?.data?.call_request?.rates?.coins
                )
              );
              dispatch(
                updateCalleeDiamonds(
                  payload?.message?.data?.call_request?.rates?.diamonds
                )
              );
              dispatch(
                updateCalleeRatePerMinute(
                  payload?.message?.data?.call_request?.rates?.usd
                )
              );

              dispatch(clearPrivateCallCallingInterval());
              dispatch(resetPrivateCallCallingTimer());

              dispatch(updateIncomingPrivateCallDialog(true));
              break;
            case pusherConst.globalPusher.payloadType.joinWaitingRoom:
              if (showLog) {
                console.log("G Pusher: Join Waiting Room");
              }

              dispatch(updatePrivateCallCallingDialog(false));

              dispatch(
                updateRequestId(
                  payload?.message?.data?.call_request?.call_request_id
                )
              );

              dispatch(updatePrivateCallAcceptedDialog(true));
              break;
            case pusherConst.globalPusher.payloadType.cancelCall:
              if (showLog) {
                console.log("G Pusher: Cancel Call");
              }

              dispatch(updateIncomingPrivateCallDialog(false));
              dispatch(updatePrivateCallWaitingRoomDialog(false));

              // Waiting Room
              dispatch(updatePrivateCallWaitingRoomDialog(false));
              dispatch(
                updateCallerUsername(
                  payload?.message?.data?.call_request?.caller?.username
                )
              );
              dispatch(
                updateCalleeUsername(
                  payload?.message?.data?.call_request?.callee?.username
                )
              );
              dispatch(updatePrivateCallDeclineDialog(true));
              break;
            case pusherConst.globalPusher.payloadType.startCall:
              if (showLog) {
                console.log("G Pusher: Start Call");
              }

              if (payload?.message?.data?.call_request) {
                dispatch(updateExitHunterMode(true));

                dispatch(
                  updateRequestId(
                    payload?.message?.data?.call_request?.call_request_id
                  )
                );
                dispatch(
                  updateCallerId(
                    payload?.message?.data?.call_request?.caller?.id
                  )
                );
                dispatch(
                  updateCallerUsername(
                    payload?.message?.data?.call_request?.caller?.username
                  )
                );
                dispatch(
                  updateCalleeId(
                    payload?.message?.data?.call_request?.callee?.id
                  )
                );
                dispatch(
                  updateRoomId(payload?.message?.data?.call_request?.room_id)
                );

                for (
                  let i = 0;
                  i < payload?.message?.data?.call_request?.users_sig?.length;
                  i++
                ) {
                  if (
                    getProfileData?.data?.id ===
                    payload?.message?.data?.call_request?.users_sig[i]?.user_id
                  ) {
                    dispatch(
                      updateCallerUserSig(
                        payload?.message?.data?.call_request?.users_sig[i]
                          ?.usersig
                      )
                    );
                  } else {
                    dispatch(
                      updateCalleeUserSig(
                        payload?.message?.data?.call_request?.users_sig[i]
                          ?.usersig
                      )
                    );
                  }
                }

                // Show video call joining overlay
                dispatch(updateShowVideoCallJoiningOverlay(true));
              }
              break;
            case pusherConst.globalPusher.payloadType.missedCall:
              if (showLog) {
                console.log("G Pusher: Missed Call");
              }

              dispatch(updateIncomingPrivateCallDialog(false));

              const toastObj = {
                message: t("inbox.call_missed"),
                autoClose: 5000,
              };
              dispatch(updateErrorToast(toastObj));
              break;
            case pusherConst.globalPusher.payloadType.rejectCall:
              if (showLog) {
                console.log("G Pusher: Reject Call");
              }

              dispatch(updatePrivateCallCallingDialog(false));

              dispatch(
                updateCallerUsername(
                  payload?.message?.data?.call_request?.caller?.username
                )
              );
              dispatch(
                updateCalleeUsername(
                  payload?.message?.data?.call_request?.callee?.username
                )
              );

              dispatch(updatePrivateCallDeclineDialog(true));
              break;
            case pusherConst.globalPusher.payloadType.endPrivateCallStandby:
              if (showLog) {
                console.log("VC Pusher: End private call standby");
              }

              dispatch(updateNotifyEndVideoCallPassthrough({}));
              dispatch(clearVideoCallChatMessages());
              dispatch(updateShowVideoCallEndingOverlay(true));
              // dispatch(updateEndVideoCall(true));

              const isViolated = payload?.message?.data?.is_violated;
              const suspension = payload?.message?.data?.suspension;
              if (suspension) {
                dispatch(updateSuspendedObj(suspension));
              }
              if (isViolated) {
                dispatch(updatePrivateCallSuspendDialog(true));
              }
              break;
            default:
              break;
          }
        }
      }
    );
  }, [channel]);

  // Lifecycle | Check for update | Reset Pusher
  useEffect(() => {
    if (resetIsMounted.current) {
      if (!globalPusherReset) return;

      if (pusherInstance) {
        // Disconnect Pusher and its channels
        pusherInstance.disconnect();
        setPusherInstance(null);

        // Update State
        dispatch(updateGlobalChannelState(false));

        setTimeout(() => {
          dispatch(updateGlobalPusherSubscribe({}));
        }, 2000);
      } else {
        const toastObj = {
          message: "Global Pusher Instance is unavailable",
          autoClose: 3000,
        };
        dispatch(updateErrorToast(toastObj));
      }
    } else {
      resetIsMounted.current = true;
    }
  }, [globalPusherReset]);

  // Lifecycle | Check for update | Destroy Pusher
  useEffect(() => {
    if (destroyIsMounted.current) {
      if (!globalPusherDestroy) return;

      if (pusherInstance) {
        pusherInstance.disconnect();
        setPusherInstance(null);

        // Update State
        dispatch(updateGlobalChannelState(false));
      }
    } else {
      destroyIsMounted.current = true;
    }
  }, [globalPusherDestroy]);

  return <div id="global-pusher-shadow-component"></div>;
};

export default GlobalPusher;
