// General
import "./payment-redirect.scss";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// Services
import {
  useLazyGetProfileQuery,
  useCheckXenopayStatusMutation,
} from "../../../services/data.service";
// Static Data
import paymentConst from "../../../const/paymentConst";
import routeConst from "../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateErrorToast,
  updateWarningToast,
} from "../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../shared/elements/spinner/spinner";

const PaymentRedirect = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    checkXenopayStatus,
    {
      data: checkXenopayStatusData,
      error: checkXenopayStatusErrorData,
      isLoading: checkXenopayStatusLoading,
      isSuccess: checkXenopayStatusSuccess,
      isError: checkXenopayStatusError,
    },
  ] = useCheckXenopayStatusMutation();

  // Redux variables
  const navigationHistory = useSelector(
    (state) => state.navigation.navigationHistory
  );
  const dispatch = useDispatch();

  // Router variables
  const location = useLocation();
  const navigate = useNavigate();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, false);

    return () => {
      removeSearchParam();
    };
  }, []);

  // Lifecycle | Check for update | User AIO API Reponse
  useEffect(() => {
    if (getProfileSuccess) {
      const queryString = new URLSearchParams(location.search);
      const transactionId = queryString.get("tx_id");

      if (!transactionId) return;

      const obj = {
        payment_gateway_txn_ref: transactionId,
      };
      checkXenopayStatus(obj);
    }
  }, [getProfileSuccess]);

  // Lifecycle | Check for update | Check Xenopay Status API Response
  useEffect(() => {
    if (checkXenopayStatusLoading) {
    } else if (checkXenopayStatusSuccess) {
      switch (checkXenopayStatusData?.status) {
        case 1:
          // Payment Success
          TagManager.dataLayer({
            dataLayer: {
              event: "PWA-CoinPurchase-success", // Do not change this, it will affect GTM and Insider
              category: "Purchase",
              action: "Quick Purchase Coins Actions",
              label: "Quick Purchase Coins Payment State - success",
              value: {},
              currency: checkXenopayStatusData?.data?.payment_intent?.currency,
              product_name: checkXenopayStatusData?.data?.product?.description,
              user_id: getProfileData?.data?.id_int,
              username: getProfileData?.data?.username,
              amount: checkXenopayStatusData?.data?.payment_intent?.amount,
            },
          });
          break;
        case -1:
          // Transaction is either completed, expired, or cancelled. Please try again with a new transaction
          const warningToastObj = {
            message: checkXenopayStatusData?.message,
            autoClose: 5000,
          };
          dispatch(updateWarningToast(warningToastObj));
          break;
        default:
          const errorToastObj = {
            message: checkXenopayStatusData?.message,
            autoClose: 5000,
          };
          dispatch(updateErrorToast(errorToastObj));
          break;
      }

      // removeSearchParam();
    } else if (checkXenopayStatusError) {
      const errorToastObj = {
        message: checkXenopayStatusErrorData?.data?.message,
        autoClose: 5000,
      };
      dispatch(updateErrorToast(errorToastObj));
    }
  }, [
    checkXenopayStatusLoading,
    checkXenopayStatusSuccess,
    checkXenopayStatusError,
  ]);

  // Event Handlers | Button
  const onProceedToMain = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-23-To-24-Button",
      },
    });

    onNavigate(routeConst.profile.wallet.path, true);
  };
  const onProceedToWallet = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-23-To-24-Button",
      },
    });

    onNavigate(routeConst.profile.wallet.path, true);
  };

  // Utility Functions
  const removeSearchParam = (key) => {
    const queryString = new URLSearchParams(location.search);
    if (queryString.has("tx_id")) {
      queryString.delete("tx_id");
    }
    if (queryString.has("status")) {
      queryString.delete("status");
    }

    navigate({ search: queryString?.toString() }, { replace: true });
  };

  if (checkXenopayStatusLoading) {
    return <Spinner />;
  } else if (checkXenopayStatusSuccess) {
    if (
      checkXenopayStatusData?.data?.payment_intent?.status ===
      paymentConst.xenopayPaymentStatus.successful
    ) {
      return (
        <div id="payment-redirect-page">
          <div className="max-width-container">
            <div className="padding-container payment-success">
              <div className="xenopay-title-container">
                <div className="payment-status-label">
                  {t("settings.support_form.payment")}{" "}
                  {checkXenopayStatusData?.data?.payment_intent?.status}
                </div>

                <div className="thank-you-label">
                  {t("upgrade.thank_you_for_purchase")}
                </div>
              </div>

              <div className="xenopay-body-container">
                <div className="your-purchase-label">
                  {t("upgrade.your_purchase")}:
                </div>

                <div className="coin-package-container">
                  <div className="icon-container">
                    <div className="coin-icon-container">
                      {getIcon("sbCoinIcon", "coin-icon")}
                    </div>
                  </div>

                  <div className="premium-description">
                    <div className="months-premium-label">
                      {checkXenopayStatusData?.data?.product?.description}
                    </div>
                    <div className="total-billed">
                      {t("upgrade.total_bill_today", {
                        amount: `${checkXenopayStatusData?.data?.payment_intent?.currency}${checkXenopayStatusData?.data?.payment_intent?.amount}`,
                      })}
                    </div>
                  </div>
                </div>

                <div className="transaction-reference">
                  {t("upgrade.transaction_reference")}:{" "}
                  {
                    checkXenopayStatusData?.data?.payment_intent
                      ?.payment_gateway_txn_ref
                  }
                </div>
              </div>

              {checkXenopayStatusData?.data?.copywriting && (
                <div className="discretion-container">
                  <div className="discretion-head">
                    {
                      checkXenopayStatusData?.data?.copywriting?.descriptor
                        ?.head
                    }
                  </div>
                  <div className="discretion-body">
                    {checkXenopayStatusData?.data?.copywriting?.descriptor?.body?.join(
                      ", "
                    )}
                  </div>
                </div>
              )}
            </div>

            <div className="bottom-container">
              <div className="continue-button" onClick={onProceedToMain}>
                {t("common.done")}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div id="payment-redirect-page">
          <div className="max-width-container payment-error-background">
            <div className="padding-container payment-error">
              <div className="payment-error-icon-container">
                {getIcon("paymentErrorIcon", "payment-error-icon")}
              </div>

              <div className="payment-error-title">
                Payment {checkXenopayStatusData?.data?.payment_intent?.status}
              </div>

              <div className="payment-error-description">
                Payment hiccup! It looks like something went awry during the
                transaction.
              </div>

              <div className="payment-error-description">Please try again.</div>
            </div>

            <div className="bottom-container">
              <div className="try-again-button" onClick={onProceedToWallet}>
                {"Got it, try again!"}
              </div>
            </div>
          </div>
        </div>
      );
    }
  } else if (checkXenopayStatusError) {
    return (
      <div id="payment-redirect-page">
        <div className="max-width-container payment-error-background">
          <div className="padding-container payment-error">
            <div className="payment-error-icon-container">
              {getIcon("paymentErrorIcon", "payment-error-icon")}
            </div>

            <div className="payment-error-title">
              Payment {checkXenopayStatusData?.data?.payment_intent?.status}
            </div>

            <div className="payment-error-description">
              Payment hiccup! It looks like something went awry during the
              transaction.
            </div>

            <div className="payment-error-description">Please try again.</div>
          </div>

          <div className="bottom-container">
            <div className="try-again-button" onClick={onProceedToWallet}>
              {"Got it, try again!"}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <Spinner />;
  }
};

export default PaymentRedirect;
