// General
import "./alphapo-payment-redirect.scss";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// Services
import {
  useLazyGetProfileQuery,
  useCheckAlphapoStatusMutation,
} from "../../../services/data.service";
// Static Data
import paymentConst from "../../../const/paymentConst";
import routeConst from "../../../const/routeConst";
// Redux
import { useDispatch } from "react-redux";
import {
  updateErrorToast,
  updateWarningToast,
} from "../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../shared/elements/spinner/spinner";

const AlphapoPaymentRedirect = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    checkAlphapoStatus,
    {
      data: checkAlphapoStatusData,
      error: checkAlphapoStatusErrorData,
      isFetching: checkAlphapoStatusFetching,
      isLoading: checkAlphapoStatusLoading,
      isSuccess: checkAlphapoStatusSuccess,
      isError: checkAlphapoStatusError,
    },
  ] = useCheckAlphapoStatusMutation();

  // Redux variables
  const dispatch = useDispatch();

  // Router variables
  const location = useLocation();
  const navigate = useNavigate();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    const queryString = new URLSearchParams(location.search);
    const status = queryString.get("status");
    const transactionId = queryString.get("txn_id");

    if (!transactionId) return;

    const obj = {
      txn_id: transactionId,
    };
    checkAlphapoStatus(obj);
  }, []);

  // Lifecycle | Unmounted
  useEffect(() => {
    return () => {
      removeSearchParam();
    };
  }, []);

  // Lifecycle | Check for update | Check Alphapo Status API Response
  useEffect(() => {
    if (checkAlphapoStatusLoading) {
    } else if (checkAlphapoStatusSuccess) {
      switch (checkAlphapoStatusData?.status) {
        case 1:
          // Payment Success
          TagManager.dataLayer({
            dataLayer: {
              event: "PWA-CoinPurchase-success", // Do not change this, it will affect GTM and Insider
              category: "Purchase",
              action: "Quick Purchase Coins Actions",
              label: "Quick Purchase Coins Payment State - success",
              value: {},
              currency: checkAlphapoStatusData?.data?.payment_intent?.currency,
              product_name: checkAlphapoStatusData?.data?.product?.name,
              user_id: getProfileData?.data?.id_int,
              username: getProfileData?.data?.username,
              amount: checkAlphapoStatusData?.data?.payment_intent?.amount,
            },
          });
          break;
        case -1:
          // Transaction is either completed, expired, or cancelled. Please try again with a new transaction
          const warningToastObj = {
            message: checkAlphapoStatusData?.message,
            autoClose: 5000,
          };
          dispatch(updateWarningToast(warningToastObj));
          break;
        default:
          const errorToastObj = {
            message: checkAlphapoStatusData?.message,
            autoClose: 5000,
          };
          dispatch(updateErrorToast(errorToastObj));
          break;
      }
    } else if (checkAlphapoStatusError) {
    }
  }, [
    checkAlphapoStatusLoading,
    checkAlphapoStatusSuccess,
    checkAlphapoStatusError,
  ]);

  // Event Handlers | Button
  const onContinue = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-23-To-24-Button",
      },
    });

    onNavigate(routeConst.search.path, true);
  };
  const onProceedToWallet = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-23-To-24-Button",
      },
    });

    onNavigate(routeConst.profile.wallet.path, true);
  };

  // Utility Functions
  const removeSearchParam = (key) => {
    const queryString = new URLSearchParams(location.search);
    if (queryString.has("status")) {
      queryString.delete("status");
    }

    navigate({ search: queryString?.toString() }, { replace: true });
  };

  if (checkAlphapoStatusLoading) {
    return <Spinner />;
  } else if (checkAlphapoStatusSuccess) {
    if (
      checkAlphapoStatusData?.data?.payment_intent?.status ===
        paymentConst.alphapoPaymentStatus.successful ||
      checkAlphapoStatusData?.data?.payment_intent?.status ===
        paymentConst.alphapoPaymentStatus.pendingPayment
    ) {
      return (
        <div id="payment-redirect-page">
          <div className="max-width-container">
            <div className="padding-container payment-success">
              <div className="xenopay-title-container">
                <div className="payment-status-label">
                  {t("purchase.payment_alphapo_title")}
                </div>

                <div className="thank-you-label">
                  <Trans
                    i18nKey={"purchase.payment_alphapo_desc"}
                    components={{ br: <br /> }}
                  />
                </div>
              </div>

              <div className="xenopay-body-container">
                <div className="your-purchase-label">
                  {t("upgrade.your_purchase")}:
                </div>

                <div className="coin-package-container">
                  <div className="icon-container">
                    <div className="coin-icon-container">
                      {getIcon("sbCoinIcon", "coin-icon")}
                    </div>
                  </div>

                  <div className="premium-description">
                    <div className="months-premium-label">
                      {checkAlphapoStatusData?.data?.product?.name}
                    </div>
                    <div className="total-billed">
                      {t("upgrade.total_bill_today", {
                        amount: `${checkAlphapoStatusData?.data?.product?.currency}${checkAlphapoStatusData?.data?.payment_intent?.amount}`,
                      })}
                    </div>
                  </div>
                </div>

                <div className="transaction-reference">
                  {t("upgrade.transaction_reference")}:{" "}
                  {
                    checkAlphapoStatusData?.data?.payment_intent
                      ?.payment_gateway_txn_ref
                  }
                </div>
              </div>

              {/* {checkXenopayStatusData?.data?.copywriting && (
                <div className="discretion-container">
                  <div className="discretion-head">
                    {
                      checkXenopayStatusData?.data?.copywriting?.descriptor
                        ?.head
                    }
                  </div>
                  <div className="discretion-body">
                    {checkXenopayStatusData?.data?.copywriting?.descriptor?.body?.join(
                      ", "
                    )}
                  </div>
                </div>
              )} */}
            </div>

            <div className="bottom-container">
              <div className="continue-button" onClick={onContinue}>
                {t("common.done")}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div id="payment-redirect-page">
          <div className="max-width-container payment-error-background">
            <div className="padding-container payment-error">
              <div className="payment-error-icon-container">
                {getIcon("paymentErrorIcon", "payment-error-icon")}
              </div>

              <div className="payment-error-title">
                {t("purchase.payment_error")}
              </div>

              <div className="payment-error-description">
                Payment hiccup! It looks like something went awry during the
                transaction.
              </div>

              <div className="payment-error-description">Please try again.</div>
            </div>

            <div className="bottom-container">
              <div className="try-again-button" onClick={onProceedToWallet}>
                {"Got it, try again!"}
              </div>
            </div>
          </div>
        </div>
      );
    }
  } else {
    return (
      <div id="payment-redirect-page">
        <div className="max-width-container payment-error-background">
          <div className="padding-container payment-error">
            <div className="payment-error-icon-container">
              {getIcon("paymentErrorIcon", "payment-error-icon")}
            </div>

            <div className="payment-error-title">
              {t("purchase.payment_error")}
            </div>

            <div className="payment-error-description">
              Payment hiccup! It looks like something went awry during the
              transaction.
            </div>

            <div className="payment-error-description">Please try again.</div>
          </div>

          <div className="bottom-container">
            <div className="try-again-button" onClick={onProceedToWallet}>
              {"Got it, try again!"}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default AlphapoPaymentRedirect;
