// General
import "./payment-method-selection.scss";
import { useEffect } from "react";
// Services
import { useLazyGetPaymentGatewayListQuery } from "../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updatePaymentMethodSelection,
  updateIsFormValid,
} from "../../../../redux/store/paymentStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../elements/spinner/spinner";

const PaymentMethodSelection = (props) => {
  const { availableGateways } = props;

  // API variables
  const [
    getPaymentGatewayList,
    {
      data: paymentGatewayListData,
      error: paymentGatewayListErrorData,
      isFetching: paymentGatewayListFetching,
      isLoading: paymentGatewayListLoading,
      isSuccess: paymentGatewayListSuccess,
      isError: paymentGatewayListError,
    },
  ] = useLazyGetPaymentGatewayListQuery();

  // Redux variables
  const paymentMethodSelection = useSelector(
    (state) => state.payment.paymentMethodSelection
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getPaymentGatewayList("?product_type=coin", false);
  }, []);

  // Event Handlers | Button
  const onSelectPaymentProvider = (provider) => {
    dispatch(updatePaymentMethodSelection(provider));

    if (provider === "xenopay") {
      dispatch(updateIsFormValid(true));

      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-S1-1.1",
        },
      });
    } else if (provider === "alphapo") {
      dispatch(updateIsFormValid(true));

      // TagManager.dataLayer({
      //   dataLayer: {
      //     event: "PWA-S1-1.2",
      //   },
      // });
    }
  };

  // Utility Functions
  const getPaymentLogo = (logo) => {
    switch (logo) {
      case "lock":
        return getIcon("creditCardIcon", "lock-icon");
      case "fpx":
        return getIcon("fpxLogo", "fpx-icon");
      case "crypto":
        return getIcon("cryptocurrencyIcon", "cryptocurrency-icon");
      default:
        return getIcon("paymentSecureLockIcon", "lock-icon");
    }
  };

  if (paymentGatewayListFetching || paymentGatewayListLoading) {
    return <Spinner />;
  } else if (paymentGatewayListSuccess) {
    return (
      <div id="payment-method-selection-subcomponent">
        <div className="select-your-payment-label">
          {t("shop.select_your_payment")}
        </div>

        <div className="payment-secure-logo-container">
          {getIcon("discreteBadge", "discrete-badge")}
          {getIcon("securityBadge", "security-badge")}
          {getIcon("sslBadge", "ssl-badge")}
          {getIcon("payWithCryptoBadge", "pay-with-crypto-badge")}
        </div>

        {paymentGatewayListData?.data?.payment_methods?.map(
          (paymentGateway, index) => {
            if (!availableGateways?.includes(paymentGateway?.provider))
              return null;

            return (
              <div
                className={`payment-method-container ${
                  paymentMethodSelection === paymentGateway?.provider
                    ? "selected-payment-provider"
                    : ""
                }`}
                key={index}
                onClick={() =>
                  onSelectPaymentProvider(paymentGateway?.provider)
                }
              >
                <div className="payment-icon-container">
                  {getPaymentLogo(paymentGateway?.icon)}
                </div>

                <div className="payment-description-container">
                  <div className="payment-title">{paymentGateway?.title}</div>

                  <div className="payment-description">
                    {paymentGateway?.paragraph}
                  </div>

                  {paymentGateway?.payment_icons?.length > 0 && (
                    <div className="sub-payment-icons-container">
                      {paymentGateway?.payment_icons?.map(
                        (paymentIcon, index) => (
                          <div
                            className="sub-payment-icon-container"
                            key={index}
                          >
                            <img
                              className="sub-payment-icon"
                              src={paymentIcon?.logo}
                              alt={paymentIcon?.title}
                            />
                          </div>
                        )
                      )}
                    </div>
                  )}
                </div>

                <div className="select-container">
                  <div className="select-payment-container">
                    {paymentMethodSelection === paymentGateway?.provider && (
                      <div className="selected-payment-inner"></div>
                    )}
                  </div>
                </div>
              </div>
            );
          }
        )}
      </div>
    );
  } else if (paymentGatewayListError) {
  }
};

export default PaymentMethodSelection;
